import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { SessionService } from './services/session.service';
import { ModalService } from './services/modal.service';
import { UtilService } from './services/util.service';
import { CalcService } from './services/calc.service';
import { AnalyticsService } from './services/analytics.service';
import { PageService } from './services/page.service';
import { UserService } from './services/user.service';
import { GroupService } from './services/group.service';
import { CustomModeService } from './services/custommode.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCacheInterceptor } from './interceptors/http-cache.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpCredentialsInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
    ModalService,
    SessionService,
    UserService,
    UtilService,
    GroupService,
    CalcService,
    AnalyticsService,
    PageService,
    CustomModeService
  ],
  exports: [
  ]
})
export class CoreModule {
  // make sure CoreModule is imported only by one NgModule
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only once (usually AppModule).');
    }
  }
}
