import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { SYSTEM } from '../../core/setup/system';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    system = SYSTEM;
    jsonPDFData: any;

    constructor(private httpClient: HttpClient,
        private sessionService: SessionService,
        private router: Router
    ) { }


    calcCSP(colldata: any): Observable<any> {

        // console.log("ApiService calcCSP()");

        var myheaders = new HttpHeaders();
        myheaders.append('Content-Type', 'text/plain');
        myheaders.append('Access-Control-Allow-Origin', '*');

        // NodeJS Call: 
        let thisurl = window.location.href;

        if (thisurl.indexOf('.cloud') === -1 && thisurl.indexOf('.com') === -1) {
            // console.log("1: " + thisurl);
            return this.httpClient.post<any>('http://localhost:3000/core/calcPost', colldata, { headers: myheaders });
        } else if  (thisurl.indexOf('-uat') > 0 && thisurl.indexOf('.cloud') > 0) {
            // console.log("2: " + thisurl);
            return this.httpClient.post<any>(environment.cspServiceUATUrl  + 'core/calcPost', colldata, { headers: myheaders });
        } else {
            // console.log("3: " + thisurl);
            return this.httpClient.post<any>(environment.cspServicePRODUrl + 'core/calcPost', colldata, { headers: myheaders });
        }
    }


/*
 * In development mode, for easier debugging, you can
*/
    genPDFData(calcdata: any): Observable<any> {

        // console.log("ApiService genPDFData()");

        // NodeJS Call
        const thisurl = window.location.href;

        if (thisurl.indexOf('.cloud') === -1 && thisurl.indexOf('.com') === -1) {
            return this.httpClient.post<any>('http://localhost:3000/core/genPDFData', calcdata);
        } else if  (thisurl.indexOf('-uat') > 0 && thisurl.indexOf('.cloud') > 0) {
            return this.httpClient.post<any>(environment.cspServiceUATUrl + 'core/genPDFData', calcdata);
        } else {
            return this.httpClient.post<any>(environment.cspServicePRODUrl + 'core/genPDFData', calcdata);
        }

    }

    getPDF(calcdata: any): Observable<any> {

        if (!calcdata) {
            return null;
        }

        // console.log("ApiService getPDF()");
        
        // PHP POST CALL
        const myheaders = new HttpHeaders();
        myheaders.append('Content-Type', 'text/plain');
        myheaders.append('Content-Type', 'application/json');
        myheaders.append('Access-Control-Allow-Origin', '*');

        const thisurl = window.location.href;
        //// console.log("API URL: " + thisurl);
        if (thisurl.indexOf('.cloud') === -1 && thisurl.indexOf('.com') === -1) {
            return this.httpClient.post<any>('http://pdfservice/' + 'pdfreport/csp_createreport.php', calcdata, { headers: myheaders });
        } else if (thisurl.indexOf('wealthmsi') !== -1) {
            //// console.log("API Prod " + thisurl.indexOf('wealthmsi'))
            return this.httpClient.post<any>(environment.pdfServicePRODUrl + 'pdfreport/csp_createreport.php', calcdata, { headers: myheaders });
        } else {
            //// console.log("API UAT");
            return this.httpClient.post<any>(environment.pdfServiceUATUrl + 'pdfreport/csp_createreport.php', calcdata, { headers: myheaders });
        }

    }

    searchCSP(searchData: any): Observable<any> {

        // NodeJS Call
        const thisurl = window.location.href;
        const myheaders = new HttpHeaders();

        const searchdata = JSON.parse(searchData);

        // console.log("ApiService searchCSP()");

        if (thisurl.indexOf('.cloud') === -1 && thisurl.indexOf('.com') === -1) {
            return this.httpClient.post<any>('http://localhost:3000/core/collsearch', searchdata, { headers: myheaders });
        } else if (thisurl.indexOf('-uat') > 0 && thisurl.indexOf('.cloud') > 0) {
            return this.httpClient.post<any>(environment.cspServiceUATUrl + 'core/collsearch', searchdata, { headers: myheaders });
        } else {
            return this.httpClient.post<any>(environment.cspServicePRODUrl + 'core/collsearch', searchdata, { headers: myheaders });
        }

    }

    // sendEmailConfirmation(email: string, mode: string): Observable<any> {
    //     return this.httpClient.post<any>(environment.apiUrl + 'core/sendEmailConfirmation', { email: email, mode: mode });
    // }

    // /**
    //  * Send user activity to server for logging.
    //  */
    // logUserActivity(activity: string, page: string, detail: string): Observable<any> {
    //     const params = {
    //         activity: activity,
    //         page: page,
    //         detail: detail,
    //     };
    //     return this.httpClient.post<any>(environment.apiUrl + 'log/userActivity', params);
    // }

    // logErrorToServer(err: HttpErrorResponse) {
    //     // log error on server
    //     const params = {
    //         ErrorCode: err.status,
    //         Source: window.location.href,
    //         Description: JSON.stringify(err.error),
    //     };
    //     this.httpClient.post<any>(
    //         environment.apiUrl + 'log/error',
    //         params).subscribe();
    // }

}
