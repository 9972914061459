import { Injectable } from '@angular/core';
import { customData } from '../../core/models/custom.model';

import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomModeService {

  customSubject = new BehaviorSubject<customData>(null);

  product: string;
  version: string;
  customData = <customData>{};
  constructor() {

    //this.cspdata
    var d = new Date();
    this.customData.year = d.getFullYear();

    this.customData.isMobile = false; //initiate as false
    // device detection   // if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.substr(0, 4))) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)) {
      this.customData.isMobile = true;
    }

  }

  setProduct(product: string) {

    this.product = (product !== null ? product.toUpperCase() : "MH");
    this.customData.product = this.product;
    this.setCustomContent();
    this.emitDataToSubscribers();
  }

  getProduct() {
    return this.product;
  }

  setVersion(version: string) {
    this.version = (version !== null ? version.toUpperCase() : "");;
    this.customData.version = this.version
    this.emitDataToSubscribers();
  }

  getVersion() {
    return this.version;
  }

  emitDataToSubscribers() {
    this.customSubject.next(this.customData);
  }


  setCustomContent() {

    // console.log("setCustomContent: " + this.customData.product);

    switch (this.product) {

      

      case "AK":
        this.customData.pageTitle = "Alaska 529 College Savings Planner";
        this.customData.reportTitle = "Alaska 529 College Savings Planner Report";
        // this.customData.contributeURL = "https://www.troweprice.com/usis/login/?pdurl=/clx-tk/token-exchange/redirect/default%3fsdurl=https://www.troweprice.com/usis/transactions/college/contribute?productType=alaska529";
        // this.customData.contributeURL2 = "https://www.troweprice.com/usis/login/?pdurl=https://www.troweprice.com/usis/my-accounts/dashboard?productType=alaska529";

        this.customData.contributeURL = "https://www.alaska529plan.com/open-an-account.html";
        this.customData.contributeURL2 = "https://www.troweprice.com/usis/login/?pdurl=/clx-tk/token-exchange/redirect/default%3fsdurl=https://www.troweprice.com/usis/transactions/college/contribute?productType=alaska529"

        this.customData.logoURL = " href='http://www.uacollegesavings.com/public/ua529/Tools-&-Resources' target='_blank' ";
        this.customData.privacypolicyURL = "https://www.alaska529plan.com/privacy-policy.html";
        this.customData.disclaimer = "";

        this.customData.disclosureCompany = "Alaska 529";
        this.customData.helpIcon = "icon_help_linkclrAK.png";
        this.customData.calcIcon = "calc_iconAK.svg";

        this.customData.savingscolor = "#92BF55";
        this.customData.unfundedcolor = "#054c70";
        this.customData.fundedcolor = "#92BF55";
        this.customData.shortfallcolor = "#CCCCCC";
        this.customData.watcolor = "#069C8F";

        this.customData.linkcolor = "#520F9A";
        this.customData.openbtncolor = "#92BF55";
        this.customData.openbtncolorhover = "#79A33E";
        this.customData.openbtnfontcolor = "#000000";
        this.customData.openbtnfontsize = "18px";
        this.customData.highlightcolor1 = "#92BF55";
        this.customData.highlightcolor2 = "#00496A";
        this.customData.grayshade = "#CECECE";
        this.customData.popoverbackground = "#92BF55";
        this.customData.popovertextcolor = "#000000";

        this.customData.actionBtncolor = "#00496A";
        this.customData.actionBtnhover = "#005D98";

        this.customData.colorL = "#92BF55";
        this.customData.colorM = "#00AEC7";
        this.customData.colorR = "#E27865";
        this.customData.btnborderradius = "0";
        this.customData.actiontext1 = "Save in Alaska, Study Anywhere";
        this.customData.actiontext2 = "Alaska 529 is designed to make it easy for families to save for a loved one's education";
        this.customData.actionbackgroundcolor = "#00496A";
        this.customData.actionbackground = "#00496A";
        this.customData.child1accordcolor = "#00496a";
        this.customData.child2accordcolor = "#92bf55";
        this.customData.child3accordcolor = "#00aec7";
        this.customData.child4accordcolor = "#e27865";
        this.customData.child5accordcolor = "#00496a";

        break;

      case "MAR":
        this.customData.pageTitle = "MCIP College Savings Planner";
        this.customData.reportTitle = "MCIP College Savings Planner Report";
        this.customData.contributeURL = "http://www.troweprice.com/usis/nasu/start/college-savings/maryland"; // "http://www.collegesavingsmd.org/enroll-now";
        this.customData.contributeURL2 = "https://www.troweprice.com/usis/login/?pdurl=/clx-tk/token-exchange/redirect/default%3fsdurl=https://www.troweprice.com/usis/transactions/college/contribute?productType=md529"; // "https://secure.collegesavingsmd.org/pls/prod/twpkwbis.P_WWWLogin";
        this.customData.logoURL = "";
        this.customData.privacypolicyURL = "https://maryland529.com/Privacy-Statement";
        this.customData.disclaimer = "Please read the entire <a target='_blank' href='https://maryland529.com/Portals/0/Files/cspm_enrollment_kit.pdf'>Enrollment Kit</a> "
          + "carefully before deciding to enroll. The Maryland Senator Edward J. Kasemeyer College Investment Plan Disclosure Statement provides investment objectives, "
          + "risks, expenses and costs, Fees, and other information you should consider carefully before investing. If you live outside of Maryland, you should consider "
          + "any college savings program offered by your home state or your Beneficiary’s home state prior to making a decision to invest in the Maryland 529 Plans. The "
          + "home state may offer state tax or other state benefits such as financial aid, scholarship funds, and protection from creditors that are only available for "
          + "investments in such state’s 529 plan. T. Rowe Price Investment Services, Inc., Distributor/Underwriter, Maryland College Investment Plan.";

        this.customData.disclosureCompany = "Maryland 529";
        this.customData.helpIcon = "icon_help_MAR.png";
        this.customData.calcIcon = "calc_icon.svg";

        this.customData.savingscolor = "#F6921F";
        this.customData.unfundedcolor = "#243B7A";
        this.customData.fundedcolor = "#F6921F";

        this.customData.shortfallcolor = "#CCCCCC";


        this.customData.openbtncolor = "#F6921F";
        this.customData.openbtnfontcolor = "#243B7A";
        this.customData.openbtncolorhover = "#C67518";
        this.customData.openbtnfontsize = "18px";
        this.customData.highlightcolor1 = "#243B7A";
        this.customData.highlightcolor2 = "#243B7A";
        this.customData.linkcolor = "#243B7A";
        this.customData.grayshade = "#EEEFF4";
        this.customData.popoverbackground = "#4F4F4F";
        this.customData.popovertextcolor = "#FFFFFF";
        this.customData.btnborderradius = "0.25rem";

        this.customData.actionBtncolor = "#243B7A";
        this.customData.actionBtnhover = "#cccccc";

        this.customData.colorL = "transparent";
        this.customData.colorM = "transparent";
        this.customData.colorR = "transparent";

        this.customData.actiontext1 = "Start saving today";
        this.customData.actiontext2 = "Put the power of time and earnings potential on your side.";
        this.customData.actionbackground = "#243B7A";
        this.customData.actionbackgroundcolor = "#243B7A";
        this.customData.child1accordcolor = "#F6921F";
        this.customData.child2accordcolor = "#0BB07B";
        this.customData.child3accordcolor = "#F8D21C";
        this.customData.child4accordcolor = "#CB2E25";
        this.customData.child5accordcolor = "#2566F2";

        break;

      case "TRP":
        // default:
        this.customData.pageTitle = "T. Rowe Price College Savings Calculator";
        this.customData.reportTitle = "T. Rowe Price College Savings Calculator Report";
        this.customData.contributeURL = "https://troweprice.com/personal-investing/troweprice-529/open-an-account.html";
        this.customData.contributeURL2 = "https://www.troweprice.com/usis/login/?pdurl=/clx-tk/token-exchange/redirect/default%3fsdurl=https://www.troweprice.com/usis/transactions/college/contribute?productType=na529";
        this.customData.logoURL = "href='http://individual.troweprice.com/public/Retail/Products-&-Services/College-Savings-Plans/h529PlanMicrosite' target='_blank' ";
        this.customData.privacypolicyURL = "https://www.troweprice.com/personal-investing/troweprice-529/privacy-policy.html";
        this.customData.disclaimer = "";

        this.customData.disclosureCompany = "T. Rowe Price College Savings Plan"; // College Savings Plan
        this.customData.helpIcon = "icon_help_linkclrTRP.png";
        this.customData.calcIcon = "calc_icon.svg";

        this.customData.savingscolor = "#7D9845";
        this.customData.unfundedcolor = "#AAAAAA";
        this.customData.fundedcolor = "#7D9845";
        this.customData.shortfallcolor = "#CCCCCC";
        this.customData.watcolor = "#CCCCCC";

        this.customData.openbtncolor = "#E17A17";
        this.customData.openbtncolorhover = "#ba6f2d";
        this.customData.openbtnfontcolor = "#000000";
        this.customData.openbtnfontsize = "18px";

        // Old
        this.customData.highlightcolor1 = "#7D9845";
        this.customData.highlightcolor2 = "#054c70";
        this.customData.linkcolor = "#054c70";
        this.customData.grayshade = "#F2F2F2";
        this.customData.popoverbackground = "#4f4f4f";
        this.customData.popovertextcolor = "#FFFFFF";

        this.customData.actionBtncolor = "#054c70";
        this.customData.actionBtnhover = "#cccccc";

        this.customData.btnborderradius = "0.25rem";

        this.customData.colorL = "transparent";
        this.customData.colorM = "transparent";
        this.customData.colorR = "transparent";


        this.customData.actiontext1 = "Start saving today";
        this.customData.actiontext2 = "Put the power of time and earnings potential on your side.";
        this.customData.actionbackground = "url('/assets/img/actionBlueBack.png') no-repeat";
        this.customData.actionbackgroundcolor = "#054c70";
        this.customData.child1accordcolor = "#05C3DE";
        this.customData.child2accordcolor = "#38939B";
        this.customData.child3accordcolor = "#614B79";
        this.customData.child4accordcolor = "#7D9845";
        this.customData.child5accordcolor = "#05C3DE";

        break;

      case "MH":
        // default:
        this.customData.pageTitle = "MetroHill College Savings Calculator";
        this.customData.reportTitle = "MetroHill College Savings Calculator Report";
        this.customData.toolName = "College Savings Calculator";
        this.customData.contributeURL = "https://troweprice.com/personal-investing/troweprice-529/open-an-account.html";
        this.customData.contributeURL2 = "https://www.troweprice.com/usis/login/?pdurl=/clx-tk/token-exchange/redirect/default%3fsdurl=https://www.troweprice.com/usis/transactions/college/contribute?productType=na529";
        this.customData.logoURL = "href='http://individual.troweprice.com/public/Retail/Products-&-Services/College-Savings-Plans/h529PlanMicrosite' target='_blank' ";
        this.customData.privacypolicyURL = "https://www.troweprice.com/personal-investing/troweprice-529/privacy-policy.html";
        this.customData.disclaimer = "";

        this.customData.disclosureCompany = "MetroHill College Savings Plan"; // College Savings Plan
        this.customData.helpIcon = "icon_help_linkclrTRP.png";
        this.customData.calcIcon = "calc_icon.svg";

        // this.customData.savingscolor = "#7D9845";
        // this.customData.unfundedcolor = "#AAAAAA";
        // this.customData.fundedcolor = "#7D9845";
        // this.customData.shortfallcolor = "#CCCCCC";
        // this.customData.watcolor = "#CCCCCC";

        this.customData.openbtncolor = "#E17A17";
        this.customData.openbtncolorhover = "#ba6f2d";
        this.customData.openbtnfontcolor = "#000000";
        this.customData.openbtnfontsize = "18px";

        // Old
        this.customData.highlightcolor1 = "#3B3B3B";
        this.customData.highlightcolor2 = "#054c70";
        this.customData.linkcolor = "#1D778C";
        this.customData.grayshade = "#F2F2F2";
        this.customData.popoverbackground = "#4f4f4f";
        this.customData.popovertextcolor = "#FFFFFF";

        this.customData.actionBtncolor =  "#0388A6"; //"#054c70";
        this.customData.actionBtnhover = "#0388A6"; // "#cccccc";

        this.customData.btnborderradius = "0.25rem";

        this.customData.colorL = "transparent";
        this.customData.colorM = "transparent";
        this.customData.colorR = "transparent";


        this.customData.actiontext1 = "Start saving today";
        this.customData.actiontext2 = "Put the power of time and earnings potential on your side.";
        this.customData.actionbackground = "url('/assets/img/actionBlueBack.png') no-repeat";
        this.customData.actionbackgroundcolor = "#0388A6"; // "#054c70";
        this.customData.child1accordcolor = "#05C3DE";
        this.customData.child2accordcolor = "#38939B";
        this.customData.child3accordcolor = "#614B79";
        this.customData.child4accordcolor = "#7D9845";
        this.customData.child5accordcolor = "#05C3DE";

        

        this.customData.unfundedcolor = "#CCCCCC";
        this.customData.fundedcolor = "#0388A6";
        this.customData.shortfallcolor = "#DB4F4B";
        this.customData.savingscolor = "#92BF55";
        this.customData.watcolor = "#EEEEEE";


        break;

    }

  }

}