export const environment = {
  production: true,
  envName: 'qa',
  enableUrlLogins: true,
  sessionTimeoutMinutes: 180,
  apiUrl: '/api/',
  nbLoginUrlDomestic: '',
  pdfServiceUATUrl: 'https://pdfservice-uat.ssnc.cloud/',
  cspServiceUATUrl: 'https://cspservice-uat.ssnc.cloud/',
  pdfServicePRODUrl: 'https://pdfservice.ssnc.cloud/',
  cspServicePRODUrl: 'https://cspservice.ssnc.cloud/'
};
