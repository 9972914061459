import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  groups = [
    {
      title: ''
    }
  ];

  constructor() { }
}
