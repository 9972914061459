import { Injectable } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { Validators, FormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { SYSTEM } from '../setup/system';
import { CurrencyPipe } from '@angular/common';
import { UserService } from './user.service';

import { CustomModeService } from '../../core/services/custommode.service';
import { customData } from '../../core/models/custom.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  // validation messages
  validations: Object = {};
  validationMessages: Object = {};
  validationErrors: Object = {};
  validationArray: string[] = [];

  // values
  minDOB: Date;
  maxDOB: Date;
  minAnnualEarnings: number;
  maxAnnualEarnings: number;
  maxPretax: number;
  maxRoth: number;
  customData = <customData>{};
  private destroyed$ = new Subject();
  MaxPretaxPct: number;
  MaxAftertaxPct: number;
  maxAge: number;
  ageofeducation: number;
  version: string;

  constructor(private currencyPipe: CurrencyPipe,
    private customModeService: CustomModeService,
    private userService: UserService) {
    this.initValues(0);
  }

  initValues(maxAge: number) {

    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.customData = data;
        this.version = this.customData.version;
      }
    });

    this.version = this.customModeService.getVersion();
    this.maxAge = (maxAge > 0 ? maxAge : SYSTEM.maxAge + 1);
    this.initValidations();

    // // max pretax/roth
    // this.maxPretax = SYSTEM.maxPretaxPct;
    // this.maxRoth = SYSTEM.maxPretaxPct;
  }
  getMaxAge() {
    return this.maxAge;
  }
  setMaxAge(maxAge: number) {
    this.maxAge = maxAge;
  }
  setAgeOfEducation(age: number) {
    this.ageofeducation = age;
  }

  initValidations() {

    this.validations = {

      age: {
        validations: [
          Validators.required,
           CustomValidators.lt(this.getMaxAge())
        ],
        messages: {
          'required': 'Please enter an age.',
          'lt': 'Please enter an age less than the college start age (' + (this.customData.version == 'A' ? this.maxAge : SYSTEM.maxAge + 1) + ').',
        }
      },

      savings: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxSavings),
        ],
        messages: {
          'required': 'Please enter an initial contribution value.',
          'lte': 'Please enter an initial contribution value less than ' + this.currencyPipe.transform(SYSTEM.maxSavings, 'USD', 'symbol', '1.0-0') + ".",
        }
      },

      tuition: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxTuition),
          CustomValidators.gte(1),
        ],
        messages: {
          'required': 'Please enter a current annual college cost.',
          'lte': 'Please enter a current annual college cost of ' + this.currencyPipe.transform(SYSTEM.maxTuition, 'USD', 'symbol', '1.0-0') + ' or less.',
          'gte': 'Please enter a current annual college cost greater than $0.'
        }
      },

      years_attending: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxNumYears),
          CustomValidators.gte(1)
        ],
        messages: {
          'required': 'Please enter a years attending college value.',
          'lte': 'Please enter a years attending college less than ' + SYSTEM.maxNumYears + '.',
          'gte': 'Please enter a years attending college greater than 0.',
        }
      },

      child_name: {
        validations: [
          Validators.required,
        ],
        messages: {
          'required': 'Please enter a child name.'
        }
      },

      college_textname: {
        validations: [
          // Validators.required,
        ],
        messages: {
  
        }
      },
      searchType: {
        validations: [
          // Validators.required,
        ],
        messages: {
      
        }
      },

      age_of_education: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxage_of_education),
          CustomValidators.gte(1)
        ],
        messages: {
          'required': 'Please enter a college start age.',
          'lte': 'Please enter a college start age less than ' + SYSTEM.maxage_of_education + '.',
          'gte': 'Please enter a college start age greater than 0.',
        }
      },

      inflation: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxinflation)
        ],
        messages: {
          'required': 'Please enter a valid college cost inflation rate.',
          'lte': 'Please enter an college cost inflation rate less than ' + SYSTEM.maxinflation + '.'
        }
      },

      rate_of_return: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxrate_of_return)
        ],
        messages: {
          'required': 'Please enter an annual rate of return.',
          'lte': 'Please enter an annual rate of return less than ' + SYSTEM.maxrate_of_return + '.'
        }
      },

     

      monthly_contrib: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxMonthlyContrib),
        ],
        messages: {
          'required': 'Please enter a monthly contribution value.',
          'lte': 'Please enter a monthly contribution value less than ' + this.currencyPipe.transform(SYSTEM.maxMonthlyContrib, 'USD', 'symbol', '1.0-0') + ".",
        }
      },

      annual_contrib: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxAnnualContrib),
        ],
        messages: {
          'required': 'Please enter an annual contribution value.',
          'lte': 'Please enter an annual contribution value less than ' + this.currencyPipe.transform(SYSTEM.maxAnnualContrib, 'USD', 'symbol', '1.0-0') + "."
        }
      },

      college_name: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },

      room_board: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },

      childNum: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },

      funded_color: {
        validations: [],
        messages: {}
      },
      unfunded_color: {
        validations: [],
        messages: {}
      },
      shortfall_color: {
        validations: [],
        messages: {}
      },
      wat_color: {
        validations: [],
        messages: {}
      },

      pctamtfunded: {
        validations: [
          Validators.required,
          CustomValidators.lte(SYSTEM.maxPctAmtFunded)
        ],
        messages: {
          'required': 'Please enter a percent to be funded value.',
          'lte': 'Please enter a percent to be funded value less than or equal to' + SYSTEM.maxPctAmtFunded + "%."
        }
      },

      filename: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },
      state: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },
      setTuition: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },
      active: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      },

      pid: {
        validations: [
          // Validators.required,
        ],
        messages: {
          // 'required': 'Please enter a child name'
        }
      }





















      // annualEarnings: {
      //   validations: [
      //     Validators.required,
      //     CustomValidators.lte(SYSTEM.maxAnnualEarnings),
      //     CustomValidators.gte(SYSTEM.minAnnualEarnings)
      //   ],
      //   messages: {
      //     'required': 'Please enter annual earnings',
      //     'lte': 'Please enter annual earnings of ' +
      //       this.currencyPipe.transform(SYSTEM.maxAnnualEarnings, 'USD', 'symbol', '1.0-0') + ' or less',
      //     'gte': 'Please enter annual earnings of at least ' +
      //       this.currencyPipe.transform(SYSTEM.minAnnualEarnings, 'USD', 'symbol', '1.0-0')
      //   }
      // },
      // modelingDate: {
      //   validations: [
      //     Validators.required,
      //   ],
      //   messages: {
      //     'required': 'Please enter modeling date'
      //   }
      // },

      // // ytdCatchup: {
      // //   validations: [
      // //     CustomValidators.lte(SYSTEM.limitCatchup),
      // //   ],
      // //   messages: {
      // //     'lte': 'Please enter a value of ' + this.currencyPipe.transform(SYSTEM.limitCatchup, 'USD', 'symbol', '1.0-0') + ' or less',
      // //   }
      // // },

      // // ytdMatch: {
      // //   validations: [
      // //     CustomValidators.lte(SYSTEM.maxMatch),
      // //   ],
      // //   messages: {
      // //     'lte': 'Please enter a value of ' + this.currencyPipe.transform(SYSTEM.maxMatch, 'USD', 'symbol', '1.0-0') + ' or less',
      // //   }
      // // },
      // ytdERContribution: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.maxEmployerContrib),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + this.currencyPipe.transform(SYSTEM.maxEmployerContrib, 'USD', 'symbol', '1.0-0') + ' or less',
      //   }
      // },








      // // // NEW vars
      // // payFreq: {
      // //   validations: [
      // //     Validators.required
      // //   ],
      // //   messages: {
      // //     'required': 'Please select a pay frequency'
      // //   }
      // // },
      // annualIncrease: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.maxAnnualIncrease),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + SYSTEM.maxAnnualIncrease + '% or less',
      //   }
      // },
      // bonusCommission: {
      //   validations: [
      //     Validators.required
      //   ],
      //   messages: {
      //     'required': 'Please select a bonus, commmission, or neither'
      //   }
      // },
      // commissionFreq: {
      //   validations: [
      //     Validators.required
      //   ],
      //   messages: {
      //     'required': 'Please select a commission frequency',
      //   }
      // },
      // commissionAmount: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.maxCommissionAmount),
      //     CustomValidators.gte(SYSTEM.minCommissionAmount),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of $' + SYSTEM.maxCommissionAmount + ' or less',
      //     'gte': 'Please enter a value greater than $0',
      //   }
      // },
      // bonusPct: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.maxBonusPct),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + SYSTEM.maxBonusPct + '% or less',
      //   }
      // },
      // RICPct: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.maxRICPct),
      //     CustomValidators.gte(SYSTEM.minRICPct)
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + SYSTEM.maxRICPct + '% or less',
      //     'gte': 'Please enter a value greater than $0',
      //   }
      // },
      // ytdAftertax: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.limit415),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + this.currencyPipe.transform(SYSTEM.limit415, 'USD', 'symbol', '1.0-0') + ' or less',
      //   }
      // },
      // ytdPretax: {
      //   validations: [
      //     CustomValidators.lte(SYSTEM.limitDeferral),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + this.currencyPipe.transform(SYSTEM.limitDeferral, 'USD', 'symbol', '1.0-0') + ' or less',
      //   }
      // },

      // //   maxPretaxPct: 50,
      // // maxAftertaxPct: 20,
      // // maxPretaxHCEPct: 50,
      // // maxAftertaxHCEPct: 20,

      // electionAftertax: {
      //   validations: [
      //     CustomValidators.lte(this.MaxAftertaxPct),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + this.MaxAftertaxPct + '% or less',
      //   }
      // },
      // electionPretax: {
      //   validations: [
      //     CustomValidators.lte(this.MaxPretaxPct),
      //   ],
      //   messages: {
      //     'lte': 'Please enter a value of ' + this.MaxPretaxPct + '% or less',
      //   }
      // }

    };
  }

  validateFormData(data: Object, form: UntypedFormGroup): Object {
    // this.initValidations();
    this.validationArray = [];
    for (const field in data) {
      if (data.hasOwnProperty(field)) {
        this.validationErrors[field] = [];
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.getValidationErrorMessage(field);
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.validationErrors[field].push(messages[key]);
              this.validationArray.push(messages[key]);
            }
          }
        }
      }
    }
    return this.validationErrors;
  }

  // validateField(value: InputValue, control: AbstractControl) {

  //   const field = value.inputName;
  //   // if (field === "electionPretax" || field === "electionAftertax") {
  //   //   this.MaxPretaxPct = this.userService.getMaxPretaxPct();
  //   //   this.MaxAftertaxPct = this.userService.getMaxAftertaxPct();
  //   // }

  //   this.validationErrors[field] = [];
  //   if (!control.valid) {
  //     const messages = this.getValidationErrorMessage(field);
  //     for (const key in control.errors) {
  //       if (control.errors.hasOwnProperty(key)) {
  //         this.validationErrors[field].push(messages[key]);
  //         this.validationArray.push(messages[key]);
  //       }
  //     }
  //   }
  //   return this.validationErrors;
  // }

  /**
   * Validate's all data.
   */
  validateAllData(form: UntypedFormGroup) {
    // validate form (first must mark all as dirty)
    const currentForm = form;
    for (const field in currentForm.controls) {
      if (currentForm.controls.hasOwnProperty(field)) {
        const control = currentForm.get(field);
        control.markAsDirty();
      }
    }

    return this.validateFormData(currentForm.value, currentForm);
  }

  /**
   * Get specific validation error messages for a field.
   */
  getValidationErrorMessage(field: string): Object {
    return this.validations[field].messages;
  }

  // isValidDate(input: FormControl) {
  //   const isValidDate = moment(input.value, 'M/D/YYYY', true).isValid();
  //   return isValidDate ? null : { isValidDate: true };
  // }
}
