import { Component, ViewChild, OnInit, Input, TemplateRef, AfterViewInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PageService } from '../../core/services/page.service';
import { Page } from '../../core/models/page.model';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker';
import { SYSTEM } from '../../core/setup/system';
import { UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { UtilService } from '../../core/services/util.service';
import { AnalyticsService } from '../../core/services/analytics.service';

import { FormValidationService } from '../../core/services/form-validation.service';
import { ApiService } from '../../core/services/api.service';
import { cspData } from '../../core/models/cspdata.model';
import { CalcService } from '../../core/services/calc.service';
import { Router } from '@angular/router';
// import { defaultStyleSanitizer } from '@angular/core/src/sanitization/sanitization';
import { ModalService } from '../../core/services/modal.service';
import { CustomModeService } from '../../core/services/custommode.service';
import { customData } from '../../core/models/custom.model';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as $ from 'jquery';

// import * as Highcharts from "highcharts";
// import patternFill from 'highcharts/modules/pattern-fill';
// patternFill(Highcharts);

@Component({
  selector: 'csp-child',
  templateUrl: './csp.component.html',
  styleUrls: ['./csp.component.scss']
})
export class cspComponent implements OnInit {

  @Input() childIndex = 0;

  oneAtATime: boolean = true;
  system = SYSTEM;
  customData = <customData>{};
  private destroyed$ = new Subject();

  // page
  page: Page;

  selectedCollegeIndex: any;

  formErrors: any = [];
  showErrorAlert = false;
  jsonCalcData = "";
  searchResults: any;
  searchType: string;
  searchNameData: string;
  searchStateData: string;
  searchStateAvgData: string;
  includeRoomBoard: boolean;
  searchInState: boolean;
  searchOutOfState: boolean;
  collegeSearchIndex: number;
  selectedState: string;
  isAverage: boolean;
  searchRadio: ``;

  // inputs
  // userInputs: UserInput;
  cspFormGroup: UntypedFormGroup;
  // masks
  currencyMask: any;
  percentMask: any;
  numberMask: any;

  // chart

  // optionsAdvanced: Object;
  // optionsSimple: Object;
  // chartAdvanced: any;
  // chartSimple: any;

  calcvalue: number;
  funded: any[];
  notfunded: any[];
  shortfall: any[];
  years: any[];

  cspdata: cspData;
  cspDataArray: cspData[] = [];
  numChildren: number;
  maxChildren: number;

  table_year: any[];
  table_funded: any[];
  table_undfunded: any[];
  table_savings: any[];
  table_shortfall: any[];
  recommended_savings_add: any;
  recommended_savings_reduce: any;
  recommended_addl_monthly_savings: any;
  addl_monthly_savings: any;

  calc_total_cost: any;
  calc_savings_pct: any;
  estimated_total_cost: any;

  calc_pct_funded: any;
  calc_amt_funded: any;
  calc_surplus_funded: any;
  calc_pctamtfunded: any;
  calc_pctamtfunded_MINUSWat: any;

  calc_monthly_additions_to_savings: any;
  calc_yearly_additions_to_savings: any;
  calc_monthly_savings_required: any;
  calc_total_funded: any;
  calc_wat_total: any;

  SAVE_PCT_TEXT: any;
  SAVINGSTEXT: any;
  COSTTEXT: any;
  COVERTEXT1: any;
  COVERTEXT2: any;
  COVERTEXT3: any;

  calcLabel: string[];
  calcValue: string[];

  college_selected: string;
  PDFFilename: string;

  product: string;
  version: string;
  college_name: string;
  college_name_prefix: string;

  displayAverage: boolean;
  fundedcolor: string;
  unfundedcolor: string;
  savingscolor: string;
  placement: string;
  states: any[];


  constructor(

    private pageService: PageService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private customModeService: CustomModeService,
    private validationService: FormValidationService,
    private utilService: UtilService,
    private apiService: ApiService,
    private currencyPipe: CurrencyPipe,
    private modalService: ModalService,
    private calcService: CalcService,
    private analyticsService: AnalyticsService

  ) { 
    
  }

  ngOnInit() {

    
    this.numChildren = 0;
    this.maxChildren = 5;
    this.collegeSearchIndex = 0;
    this.displayAverage = true;
    this.searchInState = true;
    this.searchOutOfState = false;
    this.searchStateAvgData = "";
    this.searchType = 'N';
    this.product = this.customModeService.getProduct();
    this.version = this.customModeService.getVersion();
    this.selectedCollegeIndex = null;

    this.fundedcolor = document.documentElement.style.getPropertyValue('--funded-color');
    this.unfundedcolor = document.documentElement.style.getPropertyValue('--unfunded-color');
    this.savingscolor = document.documentElement.style.getPropertyValue('--savings-color');
    this.states = SYSTEM.states;


    this.initUserInputs();
    this.initForm();
    this.setFormValues();
    this.initPageInputs();
    this.selectedState = "";
    this.userService.initUser();
    this.initMasks();
    this.isAverage =  true;

    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {

      this.college_name  = this.cspDataArray[this.childIndex]['college_name'];

      if (!this.cspDataArray[this.childIndex]['setTuition']) {
        if (data !== null) {
          this.customData = data;
          this.version = this.customData.version;

          if (this.customData.product === "AK") {
            this.selectedState = "AK";
            this.searchType = "A";
            this.searchCSP('search_by_avg', 'AK', 'default');
          } else if (this.customData.product === "MAR") {
            this.selectedState = "MD";
            this.searchType = "A";
            this.searchCSP('search_by_avg', 'MD', 'default');
          } else {
            this.searchCSP('search_by_avg', 'NAT', 'default');
          }
        }
      }
    });

    this.userService.calcSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        let cspData = data;
        let x = 1;
      }
    });

    //this.scrollTop();

  }


  compareFn(n: any): boolean {
    if (n === 1) {
      //console.log("compareFn true: " + n);
      return true;
    } else {
      //console.log("compareFn false: " + n);
      return false;
    }
  }

  // keyDownHandler(event: Event) {
  //   if (event.which === 32)
  //     event.preventDefault();
  // }

  setAdvanced() {

    
    this.analyticsService.sendEvent("switchAdvanced");
    this.customModeService.setVersion("A");
    this.version = "A";

    this.scrollTop();

  }

  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  scrollTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  open(panelNum) {
    const panel = this[`p${panelNum}`];
    panel.isOpen = !panel.isOpen;
  }

  initMasks() {

    this.currencyMask = this.utilService.getCurrencyMask();
    this.percentMask = this.utilService.getPercentageMask();
    this.numberMask = this.utilService.getNumberMask();
  }




  initUserInputs() {

    this.cspDataArray = this.userService.getCspData();

    // console.log("");
    // for (let i = 0; i < this.cspDataArray.length; i++) {
    //   let nm = this.cspDataArray[i]['child_name'];
    //   console.log("initUserInputs - Name: " + nm + ", college_name: " + this.cspDataArray[i]['college_name'] + ", tuition: " + this.cspDataArray[i]['tuition']);
    // }

    if (this.numChildren == 0) {
      this.numChildren++;
    }

  }

  openModal(template: TemplateRef<any>, modalId: string) {
   
    if (modalId === "collegeSearchModal") {
      this.analyticsService.sendEvent("collegeSearch");
    }

    this.modalService.show(template, modalId);
  }


  closeModal(modalId: string) {
    //this.closeSearch('collegeSearchModal', 0);
    this.modalService.hide(modalId);
  }

  setDisplay(dispvalue: string) {

    //alert("setDisplay");
    if (dispvalue === "average") {
      this.displayAverage = true;
      this.searchResults = null;
      this.isAverage = true;
    } else {
      this.displayAverage = false;
      this.searchResults = null;
      this.isAverage = false;
    }

  }



  initForm() {

    // let cspData = this.userService.getUserInputs(this.numChildren, true);

    // //console.log("initForm cspData: " + this.childIndex);


    this.cspFormGroup = this.formBuilder.group(this.cspDataArray[this.childIndex]); // childIndex

    //public addValidators(form: FormGroup) {
    for (const key in this.cspFormGroup.controls) {

      this.cspFormGroup.controls[key].setValidators(this.validationService.validations[key].validations);

    }

    // subscribe to form value changes
    this.cspFormGroup.valueChanges.subscribe(data => {

      this.formErrors = this.validationService.validateFormData(data, this.cspFormGroup);
      if (this.formErrors == "") {
        this.checkForm();
      }

    });

  }

  checktuition() {
    // tuition
  }
  checknumyears() {

  }

  setFormValues() {

  }

  initPageInputs() {

  }
  setNatAvg(val: boolean) {

    this.searchStateAvgData = "NAT";
    this.cspFormGroup.controls['state'].setValue(this.searchStateAvgData);
    this.cspDataArray[this.childIndex]['state'] = this.searchStateAvgData;

    this.searchType = (val ? "N" : "A");
    if (this.searchType === "A") {
      this.searchResults = null;
    }

  }
  setInState(val: boolean) {
    this.searchInState = val;
    // this.executeSearch();
  }
  setincludeRoomBoard(val: boolean) {
    this.includeRoomBoard = val;
    // this.executeSearch();
  }
  navBack() {
    this.pageService.navBack();
  }

  checkForm() {

    //this.cspDataArray = this.userService.getCspData();
    this.cspDataArray[this.childIndex] = this.cspFormGroup.getRawValue();
    this.userService.updateCspData(this.cspDataArray);

    this.calculate();

  }

  calculate() {

    this.cspDataArray = this.userService.getCspData();
    // console.log("");
    // for (let i = 0; i < this.cspDataArray.length; i++) {
    //   let nm = this.cspDataArray[i]['child_name'];
    //   console.log("calculate - Name: " + nm + ", college_name: " + this.cspDataArray[i]['college_name'] + ", tuition: " + this.cspDataArray[i]['tuition']);
    // }

    for (let i = 0; i < this.cspDataArray.length; i++) {
      this.cspDataArray[i]['active'] = false;
      if (i == this.childIndex) {
        this.cspDataArray[i]['active'] = true;
      }
    }

    if (this.cspFormGroup.valid) {
      this.cspCalc();
    } else {
      this.showErrorAlert = true;
    }

  }


  searchCSP(searchtype: string, searchsubtype: string, type: string) {

    //console.log("searchCSP");
    type = (!type ? "" : type);
   

    let searchType = "";
    let searchValue = "";
    let searchState = "";
    let childIndex = this.childIndex;
    this.college_name_prefix = "";
    this.cspDataArray[childIndex].searchType = "A";

    switch (searchtype) {

      case "search_by_avg":
        searchType = "search_by_avg";
        this.cspDataArray[childIndex].searchType = "A";

        // searchValue = (searchsubtype !== "" ? searchsubtype : this.searchStateAvgData);
        searchValue = (this.searchStateAvgData !== "" ? this.searchStateAvgData : searchsubtype);
        this.college_name_prefix = searchValue;
        break;

      case "search_by_name":
        searchType = "search_by_name";
        this.cspDataArray[childIndex].searchType = "N";
        // this.searchType = "N";
        searchValue = this.searchNameData.trim();
        break;

      case "search_by_state":
        this.searchNameData = "";
        searchType = "search_by_state";
        this.cspDataArray[childIndex].searchType = "S";
        searchValue = this.searchStateAvgData;
        break;
    }

    this.cspFormGroup.controls['state'].setValue(this.searchStateAvgData);
    this.cspDataArray[childIndex]['state'] = this.searchStateAvgData;
    this.userService.updateCspData(this.cspDataArray);

    let searchData = JSON.stringify({ searchtype: searchType, searchvalue: searchValue, searchRoomBoard: this.includeRoomBoard, searchInState: this.searchInState });

    this.apiService.searchCSP(searchData).subscribe(
      resp => {

        this.searchResults = resp;

        if (type === "default") {

          /*** Set the default to Public 4-year Avg - Out of State ***/
          this.searchInState = true;
          this.includeRoomBoard = true;
          this.collegeSearchIndex = 1;
          this.set_college_selected(null, this.collegeSearchIndex);
          this.executeSearch();
          this.collegeSearchIndex = 0;
          //this.calculate();
          //this.checkForm();

        }

      }
    );

    let tony = 1;

  }


  executeSearch() {

    // this.cspFormGroup.controls['college_textname'].setValue(this.searchResults[this.collegeSearchIndex].name);
    // this.cspFormGroup.controls['searchType'].setValue(this.searchType);
    // this.cspFormGroup.controls['room_board'].setValue(this.searchResults[this.collegeSearchIndex].room_board);


    let tuition = 0;
    if (this.displayAverage) {  // if (this.searchType == "A") {
      // let tuition = this.searchResults[this.collegeSearchIndex].tuition;
      // let books = (this.searchResults[this.collegeSearchIndex].books !== undefined ? this.searchResults[this.collegeSearchIndex].books : 0);
      // let rb = (this.includeRoomBoard ? this.searchResults[this.collegeSearchIndex].room_board : 0);
      tuition = this.searchResults[this.collegeSearchIndex].tuition + (this.searchResults[this.collegeSearchIndex].books !== undefined ? this.searchResults[this.collegeSearchIndex].books : 0)
        + (this.includeRoomBoard ? this.searchResults[this.collegeSearchIndex].room_board : 0);
    } else {
      tuition = (this.searchInState ? this.searchResults[this.collegeSearchIndex].tuit_in : this.searchResults[this.collegeSearchIndex].tuit_out)
        // + this.searchResults[this.collegeSearchIndex].books
        + (this.includeRoomBoard ? this.searchResults[this.collegeSearchIndex].room_board : 0);
    }

    this.cspFormGroup.controls['college_textname'].setValue(this.searchResults[this.collegeSearchIndex].name);
    this.cspFormGroup.controls['searchType'].setValue(this.searchType);
    this.cspFormGroup.controls['room_board'].setValue(this.searchResults[this.collegeSearchIndex].room_board);

    this.cspFormGroup.controls['tuition'].patchValue(tuition);
    this.cspFormGroup.controls['college_name'].patchValue(this.searchResults[this.collegeSearchIndex].name);

    this.college_name = this.searchResults[this.collegeSearchIndex].name;
    if (this.customData.product !== "TRP") {
      this.college_name = this.college_name_prefix + " " + this.searchResults[this.collegeSearchIndex].name;
    }

    this.cspDataArray[this.childIndex] = this.cspFormGroup.getRawValue();
    this.cspDataArray[this.childIndex]['searchType'] = (this.displayAverage ? 'A' : 'S');

    this.cspDataArray[this.childIndex]['setTuition'] = true;
    this.userService.updateCspData(this.cspDataArray);
    this.calculate();
  }

  setAge(event: any) {

    let age = parseInt(event.target.value);
    this.validationService.initValues(age);
    this.validationService.setMaxAge(age);

  }

  closeSearch(modalName: string, closeType: number) {

    if (closeType) {
      this.executeSearch();
    }
    this.closeModal(modalName);

  }

  set_college_selected(event: any, defaultAvg: number) {

    this.collegeSearchIndex = (defaultAvg ? defaultAvg : (event ? event.target.value : this.selectedCollegeIndex)); /// (event ? event.target.index : this.collegeSearchIndex));
    //console.log("this.collegeSearchIndex: " + this.collegeSearchIndex);
    if (event) {
      //event.target.className = "highlightCollege";
      //this.collegeSearchIndex = event.target.value;
    }
    this.selectedCollegeIndex = this.collegeSearchIndex;

  }

  cspCalc() {

    // NodeJS call
    // 
    let calcAll = this.userService.getCspData();
    let calcData = calcAll[this.childIndex];

    this.apiService.calcCSP(calcAll).subscribe(
      resp => {

        this.jsonCalcData = resp; // .calculation;

        this.calcService.setCalcData(this.jsonCalcData);

      }
    );

    let x = 1;

  }

  infoIconClick(popover, infoText: string) {

    popover._elementRef.nativeElement.autofocus = true;
    // prevent auto close on click outside popover
    popover.autoClose = true;

    // cloase any other open first...
    $('ngb-popover-window').hide();

    if (popover.isOpen()) {
      this.setAriaLive("Info Box closed for " + infoText);
      popover.close();
    } else {

      popover.open();
      this.setAriaLive("Info Box opened for " + infoText);
      $("ngb-popover-window").attr('tabindex', 0);
      $("ngb-popover-window").focus();

    }


  }

  setAriaLive(item: any) {
    let adaAnounce = document.getElementById('adaAnounce');
    if (adaAnounce) {
      adaAnounce.textContent = item + ' selected';
    }
  }







}
