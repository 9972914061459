import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe, DecimalPipe, DatePipe, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgIdleModule } from '@ng-idle/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TextMaskModule } from 'angular2-text-mask';
// import { CustomFormsModule } from 'ngx-custom-validators';
import { CustomFormsModule } from 'ng2-validation';


import { NumericDirective } from './directives/numeric.directive';
import { ValidateOnBlurDirective } from './directives/validate-on-blur.directive';
import { BootstrapModule } from './bootstrap/bootstrap.module';
import { TermsModule } from './terms/terms.module';
import { FormErrorComponent } from './form-error/form-error.component';
import { NavComponent } from './nav/nav.component';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { InfoButtonComponent } from './info-button/info-button.component';
// import { ChildchartComponent } from './childchart/childchart.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    HttpClientXsrfModule,
    TermsModule,
    BootstrapModule,
    NgIdleModule.forRoot(),
    NgxSpinnerModule,
    TextMaskModule,
    CustomFormsModule,

  ],
  declarations: [
    NumericDirective,
    ValidateOnBlurDirective,
    NoCommaPipe,
    FormErrorComponent,
    NavComponent,
    InfoButtonComponent,
    // ChildchartComponent

  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    PercentPipe,

  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapModule,
    HttpClientModule,
    HttpClientXsrfModule,
    TermsModule,
    ReactiveFormsModule,
    NgIdleModule,
    NumericDirective,
    ValidateOnBlurDirective,
    NoCommaPipe,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    PercentPipe,
    NgxSpinnerModule,
    FormErrorComponent,
    NavComponent,
    TextMaskModule,
    CustomFormsModule,
    InfoButtonComponent,
  ]
})
export class SharedModule { }
