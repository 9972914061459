import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

import { BehaviorSubject } from 'rxjs';
import { CalcService } from './calc.service';
import { SYSTEM } from '../setup/system';

import { cspData } from '../models/cspdata.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  cspData = <cspData>{};
  calcSubject = new BehaviorSubject<cspData>(null);


  cspDataArray: cspData[] = [];

  constructor(private sessionService: SessionService) { }

  initUser() {

    // start session timer
    this.sessionService.init();

    const cspdata = JSON.parse(this.sessionService.getSessionItem('cspData'));

    // inputs found - assign to class property
    if (cspdata !== null) {
      this.cspData = cspdata;
    }

    // emit  data to subscribers
    this.emitDataToSubscribers();

  }

  emitDataToSubscribers() {

    this.calcSubject.next(this.cspData);
  }


  updateSessCspData(cspdata: any) {

    this.sessionService.saveSessionItem('cspData', JSON.stringify(cspdata));

    this.cspData = cspdata;

    this.emitDataToSubscribers();

  }

  getSessCspData() {

    const tmpData = JSON.parse(this.sessionService.getSessionItem('cspData'));
    return tmpData;

  }




  updateCspData(cspdata: any) {

    //this.sessionService.saveSessionItem('cspData', JSON.stringify(cspdata));

    this.cspDataArray = cspdata;

    //this.emitDataToSubscribers();

  }

  getCspData() {

    // const tmpData = JSON.parse(this.sessionService.getSessionItem('cspData'));
    return this.cspDataArray;

  }




  getUserInputs(childNum: number, active: boolean): cspData {


    let cname = "Child " + (childNum + 1);

    this.cspData = {
      tuition: 10000,
      setTuition: false,
      child_name: cname,
      age: SYSTEM.age,
      savings: SYSTEM.initial_contrib,
      monthly_contrib: SYSTEM.monthly_contrib,
      annual_contrib: SYSTEM.annual_contrib,
      college_name: "Average Tuition",
      pctamtfunded: SYSTEM.maxPctAmtFunded,
      age_of_education: SYSTEM.age_of_education,
      years_attending: SYSTEM.years_attending,
      inflation: SYSTEM.inflation,
      rate_of_return: SYSTEM.rate_of_return,
      college_textname: "",
      searchType: "A",
      room_board: 0,
      active: true,
      filename: "",
      childNum: 0,
      funded_color: "",
      unfunded_color: "",
      shortfall_color: "",
      wat_color: "",
      pid: "",
      state: ""
    };



    // emit  data to subscribers
    // this.emitDataToSubscribers();

    return this.cspData;

  }

  setName(cname: string) {


  }



  getVersion() {
    return null; //this.userInputs.version;
  }




  logout() {
    this.sessionService.clearStorage();
  }
}
