import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appNumeric]'
})
export class NumericDirective implements OnInit {

    /**
     * Creates an instance of NumericDirective.
     *
     * @param {ElementRef} elementRef
     * @param {NgControl} model
     */
    constructor(
        private elementRef: ElementRef,
        private model: NgControl
    ) { }

    /**
     * Formats a masked input field (i.e. $, %) to numeric value.
     */
    ngOnInit(): void {
        this.model.control.valueChanges.subscribe(() => {
            let newValue = this.elementRef.nativeElement.value.replace(/[^\d.-]/g, '');
            if (newValue !== '') {
                newValue = Number(newValue);
            }
            this.model.control.setValue(newValue, {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false
            });
        });
  }
}
