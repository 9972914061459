import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
//import { ResultsComponent } from './pages/results/results.component';
import { cspComponent } from './pages/csp/csp.component';
// import { bonuscommissionComponent } from './pages/bonuscommission/bonuscommission.component';
// import { ricComponent } from './pages/ric/ric.component';
// import { ytdcontribComponent } from './pages/ytdcontrib/ytdcontrib.component';
// import { currentcontribComponent } from './pages/currentcontrib/currentcontrib.component';
// import { VersionComponent } from './pages/version/version.component';


const routes: Routes = [
    { path: '', component: HomeComponent },
    // { path: 'versionT', component: VersionComponent },
    // { path: 'versionN', component: VersionComponent },
    { path: "csp", component: cspComponent },
    // { path: 'bonuscommission', component: bonuscommissionComponent },
    // { path: 'ric', component: ricComponent },
    // { path: 'ytdcontrib', component: ytdcontribComponent },
    // { path: 'currentcontrib', component: currentcontribComponent },
    // { path: 'results', component: ResultsComponent },
    {
        path: '',
        loadChildren: () => import('./common-pages/common-pages.module').then(m => m.CommonPagesModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {}),
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled'
        }),
    ],
 
    exports: [RouterModule]
})
export class AppRoutingModule { }

/*
{ path: '', component: HomeComponent },
{ path: 'versionT', component: VersionComponent },
{ path: 'versionN', component: VersionComponent },
{ path: 'pay', component: cspComponent },
{ path: 'bc', component: bonuscommissionComponent },
{ path: 'ric', component: ricComponent },
{ path: 'ytdcontrib', component: ytdcontribComponent },
{ path: 'nowcontrib', component: currentcontribComponent },
{ path: 'results', component: ResultsComponent },
*/