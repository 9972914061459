import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { cspComponent } from './pages/csp/csp.component';
import { HomeComponent } from './pages/home/home.component';
import { ChildchartComponent } from './shared/childchart/childchart.component';

import { LayoutModule } from './layout/layout.module';

import { HighchartsChartModule } from 'highcharts-angular';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

declare var require: any;
export function highchartsFactory() {
  return require('highcharts');
};

@NgModule({
  declarations: [
    AppComponent,
    cspComponent,
    HomeComponent,
    ChildchartComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    LayoutModule,
    HighchartsChartModule,
    TooltipModule.forRoot(),
    AppRoutingModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    BrowserAnimationsModule
    //ChartModule
  ],
  providers: [
    // { provide: HighchartsStatic, useFactory: highchartsFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
