import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private apiService: ApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {




    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errMsg = '';
          // Client Side Error
          if (error.error instanceof ErrorEvent) {
            errMsg = `Error: ${error.error.message}`;
          } else {  // Server Side Error
            errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            switch (error.status) {
              case 401:    // unauthorized
                this.router.navigate(['/login']);
                break;
              case 403:    // forbidden
                this.router.navigate(['/access']);
                break;
              case 404:    // not found
                this.router.navigate(['/notfound']);
                break;
              case 500:    // internal server error
                this.router.navigate(['/error']);
                break;
              case 503:    // service unavailable
                this.router.navigate(['/access']);
                break;
              case 0:    // PHP PDF Report OK
                return;
                break;
              default:
                if (!request.url.includes('log/error')) {
                  //this.apiService.logErrorToServer(error);
                }
                this.router.navigate(['/error']);
                break;
            }
          }
          return throwError(errMsg);
        })
      );

  }
}
