import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsContentComponent } from './terms-content/terms-content.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { BootstrapModule } from '../bootstrap/bootstrap.module';



@NgModule({
  imports: [
    CommonModule,
    BootstrapModule,
  ],
  declarations: [
    TermsContentComponent,
    TermsModalComponent,
  ],
  exports: [
    TermsContentComponent,
    TermsModalComponent
  ]
})
export class TermsModule { }
