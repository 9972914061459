import { Component, OnInit } from '@angular/core';
import { customData } from '../core/models/custom.model';
import { CustomModeService } from '../core/services/custommode.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  customData = <customData>{};
  private destroyed$ = new Subject();

  constructor(private customModeService: CustomModeService) { }

  ngOnInit() {

    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.customData = data;
      }
    });
  }



}
