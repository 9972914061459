<!-- <div>{{pageTitle}} {{this.product}}</div> -->
<!-- <ng-container >
    <div class="row">
        <div class="col-sm-4 left"> <div class="calcImage{{this.product}}"></div></div>
        <div class="col-sm-8 right"></div>
    </div>

</ng-container> -->
<ng-container *ngIf="customData.product !== 'MH'">
    <title>{{pageTitle}}</title>
    <div class="bg-white">
        <div class="container mt-3">
            <!-- <div class="row logo-row align-items-center mb-2 pt-2 pb-2">
                <div class="col-sm-12 col-lg-4">
                    <a class="logo" href="{{customData.logoURL}}" target="_blank"></a>
                </div>
            </div> -->
        </div>
    </div>
</ng-container>


<ng-container *ngIf="customData.product === 'MH'">
    <div class="bg-white">
        <div class="container mt-3">
            <div class="row logo-row  align-items-start ml-1 mb-2 pt-2 pb-2 row-no-margin">
                <div class="col-sm-12 col-md-4 left p-0">
                    <div class="calcImage{{this.product}}"></div>
                    <!-- <a class="logo" href="{{customData.logoURL}}" target="_blank"></a> -->
                </div>
                <h1 class="col-sm-12 col-md-8 p-0 toolName">{{this.toolName}}</h1>
            </div>
            <hr class="headerHR">
        </div>
    </div>
</ng-container>