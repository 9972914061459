import { Component, OnInit, TemplateRef, Input } from '@angular/core';

import { ModalService } from '../../core/services/modal.service';
import { Page } from '../../core/models/page.model';
import { PageService } from '../../core/services/page.service';
import { CustomModeService } from '../../core/services/custommode.service';
import { customData } from '../../core/models/custom.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  page: Page;
  product: string;
  version: string;
  customData = <customData>{};
  private destroyed$ = new Subject();
  currentYear: string;


  constructor(private modalService: ModalService,
    private customModeService: CustomModeService,
    private pageService: PageService) { }

  ngOnInit() {
    this.initPageData();

    this.product = this.customModeService.getProduct();
    this.version = this.customModeService.getVersion();

    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.customData = data;
      }
    });

    var d = new Date();
    this.currentYear = d.getFullYear().toString();


  }

  initPageData() {
    // this.pageService.pageSubject.subscribe(page => {
    //   this.page = page;
    // });
  }

  openModal(template: TemplateRef<any>, modalId: string) {
    this.modalService.show(template, modalId);
  }


  closeModal(modalId: string) {
    this.modalService.hide(modalId);
  }

}
