<!-- Bottom Panel -->
<!-- Bottom Panel -->
<!-- Bottom Panel -->
<div *ngIf="customData.product === 'MH'" class="container-fluid mt-4">
    <div class="row ">
        <aside role="complementary" class="col-12 specialistBar">
            <div class="container mb-4">
                <div class=" specialistTitle">
                    <span class="action-title">Call to Action </span>
                </div>
                <div class=" specialistText">
                    Corporis, minima voluptates debitis quas velit quod sequi quis dignissimos pariatur aliquam bunt.
                </div>
            </div>
        </aside>
    </div>
</div>

<div class="container mb-4">
    <div class="row">
        <div class="col-12 borderLine">
            <div class="disclosure mt-5">
                <div class="disclosureTitle">Important Disclosure</div>
                <br/>

                <p>   
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum suscipit harum culpa architecto cum provident voluptatem in hic ex, quisquam et possimus, consectetur debitis consequatur ipsam dolores nobis repudiandae veritatis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, minima voluptates debitis quas velit quod sequi quis dignissimos pariatur aliquam sunt a! Animi, incidunt nam. Vero suscipit eum odio officiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum suscipit harum culpa architecto cum provident voluptatem in hic ex, quisquam et possimus, consectetur debitis consequatur ipsam dolores nobis repudiandae veritatis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, minima voluptates debitis quas velit quod sequi quis dignissimos pariatur aliquam sunt a! Animi, incidunt nam. Vero suscipit eum odio officiis.
                </p>  
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum suscipit harum culpa architecto cum provident voluptatem in hic ex, quisquam et possimus, consectetur debitis consequatur ipsam dolores nobis repudiandae veritatis?  Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum suscipit harum culpa architecto cum provident voluptatem in hic ex, quisquam et possimus, consectetur debitis consequatur ipsam dolores nobis repudiandae veritatis?  
                </p>  
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum suscipit harum culpa architecto cum provident voluptatem in hic ex, quisquam et possimus, consectetur debitis consequatur ipsam dolores nobis repudiandae veritatis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, minima voluptates debitis quas velit quod sequi quis dignissimos pariatur aliquam sunt a! Animi, incidunt nam. Vero suscipit eum odio officiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum suscipit harum culpa architecto cum provident voluptatem in hic ex, quisquam et possimus, consectetur debitis consequatur ipsam dolores nobis repudiandae veritatis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, minima voluptates debitis quas velit quod sequi quis dignissimos pariatur aliquam sunt a! Animi, incidunt nam. Vero suscipit eum odio officiis. 
                </p>
                                
                <!-- <p>The information entered is only used within the calculator and not for any other purpose. For further information please view the {{customData.disclosureCompany}}
                    <a href='{{customData.privacypolicyURL}}' target='_blank'> privacy policy</a>.</p>
                <p>The purpose of the calculator is to provide investors with a reasonable estimate of the amount that their college savings could generate under hypothetical market scenarios over a period of time. </p>
                <p><b>The calculator can provide information about three variables:</b></p>
                <UL>
                    <LI>The initial investment needed to reach a goal;</LI>
                    <LI>The periodic investments needed; and </LI>
                    <LI>The total or "target" amount that a given investment approach may generate over time. </LI>
                </UL>
                <p><b>Material limitations include:</b></p>
                <UL>
                    <LI>For the purposes of this calculator, college costs include tuition, fees, and room & board as supplied to the College Board and SS&C. from the institutions. The average costs are based on two and four year, public and private colleges
                        for each state and nationally. National average costs include tuition & fees, and room & board. National Average Cost Data: © {{customData.year}}. The College Board, "Trends in College Pricing {{customData.year-1}}." Individual
                        College Cost Data: © {{customData.year}} SS&C.</LI>
                    <LI>This calculator assumes that overall college costs (education cost inflation) will increase each year by the rate you selected for each child's costs, with a default of 5%. The impact of inflation on higher education expenses is uncertain
                        and the rate of inflation could exceed the rate of return on your investments. Inflation is assumed constant, so variations are not reflected in our calculations. </LI>
                    <LI>This calculator also allows you to enter your hypothetical average annual rate of return. You may wish to choose a conservative hypothetical annual rate of return. The default is 6% should you not choose a specific return.</LI>
                    <LI>Taxes are not taken into account. The effect of federal tax laws may vary depending whether the assets are used for qualified educational expenses and who receives the benefit of the distribution. State tax laws also vary, and you
                        should check with your tax adviser regarding the effect of your state's tax law on any 529 distribution.</LI>
                </UL>
                <p>
                    The cost figures provided are solely estimates, not guarantees, and actual increases may be higher.
                </p>
                
                <p><b>IMPORTANT: The projections or other information generated by the {{customData.pageTitle}} regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of
                        future results. The
                        simulations are based on assumptions. There can be no assurance that the projected or simulated results will be achieved or sustained. The charts present only a range of possible outcomes. Results will vary with each use of the calculator and over time, and such results may
                        be better or worse than the simulated scenarios. Clients should be aware that the potential for loss (or gain) may be greater than demonstrated in the simulations.</b></p>
                <p>The results are not predictions, but they should be viewed as reasonable estimates. Calculator illustrations are produced by SS&C.</p>
                <p><i><span [innerHTML]="customData.disclaimer"></span></i></p> -->
            </div>
        </div>
    </div>
    <div class="row disclosure mt-2">
        <div class="col-8 float-left align-self-center">
            &copy; {{currentYear}} SS&C. All rights reserved.
        </div>
        <div class="col-4 float-right align-self-center">
            <div class="sscLogo float-right"> </div>
            <img class="float-right" src="/assets/img/ssncLogo.png" height=45px vspace="0" alt="SS&C logo" />
            <!-- <div class="footer-logo-txt mt-2 float-right">Master Complexity&trade;</div> -->
        </div>
    </div>

</div>

<ng-template #disclaimer>

</ng-template>