import { Injectable } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor() { }

    /**
     * Create a JavaScript date.
     * Safari has issues parsing dates with '-' (how they are stored in MySQL).
     * This will replace '-' with '/' and create JavaScript date for use in all browsers.
     *
     * @param {Date} date
     * @returns {Date}
     */
    createDate(date: Date | string): Date {
        return new Date(String(date).replace(/-/g, '/'));
    }




    getCurrencyMask() {
        return createNumberMask({ allowDecimal: true });
    }
    getNumberMask() {
        return createNumberMask({ prefix: '', allowDecimal: false });
    }

    getPercentageMask() {
        return createNumberMask({ prefix: '', suffix: '%', allowDecimal: true, decimalLimit: 1 });
    }

    getDateMask() {
        return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }


    /**
     * Round to precision.
     *
     * @param {number} val
     * @param {number} decimals
     * @param {string} [mode]
     * @returns {number}
     */
    roundTo(val: number, decimals?: number, mode?: string): number {
        if (decimals === undefined) {
            decimals = 0;
        }

        const multiplicator = Math.pow(10, decimals);
        val = parseFloat((val * multiplicator).toFixed(11));
        let test = 0;
        if (mode === 'ceil') {
            test = (Math.ceil(val) / multiplicator);
        } else if (mode === 'floor') {
            test = (Math.floor(val) / multiplicator);
        } else {
            test = (Math.round(val) / multiplicator);
        }

        return +(test.toFixed(decimals));
    }

    getCookie(name: string) {
        const cookie = document.cookie.split(';');
        const nameEQ = name + '=';
        for (let i = 0; i < cookie.length; i++) {
            let c = cookie[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    scrollToElement($element: HTMLElement): void {
        $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }



    currencyFormatter(num: any, pad: any = 0) {
        let i;
        if (isNaN(num)) {
            num = 0;
        }
        num = num.toString().replace(/\$|\,/g, '');
        let numlen = num.length;
        let sign = (num == (num = Math.abs(num)));
        num = Math.floor(num * 100 + 0.50000000001);
        let cents = num % 100;
        num = Math.floor(num / 100).toString();

        if (cents < 10) {
            //cents = "0" + cents;
            cents = parseInt("0" + cents);
        }
        let numcommas = 0;
        for (i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
            num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
            ++numcommas;
        }
        let retstr = (sign ? '' : '-') + '$' + num;
        if (pad > 3) {
            let padlen = pad - numlen;
            for (i = 0; i < padlen - numcommas; ++i) {
                retstr = '  ' + retstr;
            }
        }
        return retstr;
    }




}
