import { Injectable } from '@angular/core';
import { SYSTEM } from '../setup/system';
import { UtilService } from './util.service';
// import { ThrowStmt } from '@angular/compiler';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { cspData } from '../models/cspdata.model';


@Injectable({
  providedIn: 'root'
})
export class CalcService {

  // user inputs
  calcdata = <any>{};
  calcSubject = new BehaviorSubject<cspData>(null);

  calcData: any[];
  jsonCalcData: any[];


  constructor(private utilService: UtilService) {

  }

  setCalcData(cspjsondata: any) {

    this.calcdata = cspjsondata;

    // emit  data to subscribers
    this.emitDataToSubscribers();

  }

  emitDataToSubscribers() {
    this.calcSubject.next(this.calcdata);
  }


}
