<ng-container *ngIf="this.customData.product !== ''">

    <div class="cspContainer">
        <ng-container *ngIf="customData.product === 'MAR'">
            <div class="row cspGutters">
                <div class="col-sm-12 cspPadding">
                    <img vspace="5" hspace="5" width="250px" src="assets/img/MaryLand529_Logo.png">
                </div>
                <div class="col-sm-12 cspPadding simpleText normal">
                    <p>Use the calculator below to help your family understand future college expenses and estimate how much to save in order to stay on track with your college savings goals.</p>
                </div>
            </div>
        </ng-container>
        <!-- container -->
        <div class="row cspGutters">
            <!-- Left Panel -->
            <!-- Left Panel -->
            <!-- Left Panel -->
            <div class="col-sm-12 col-md-6">
                
                <div id="sectionHeader" class="sectionHeader hrUnderL row noRowGutter">
                    <ng-container *ngIf="this.customData.product === 'MH'">
                       
                        <!-- 
                        <div [ngClass]="{'col-9': version == 'A', 'col-12': version == 'S'}" class="">
                            <img class="float-left" src="/assets/img/graduation-cap.png" height=43px vspace="0" hspace="0" alt="" />
                            <div *ngIf="version == 'S'" class="simpleText-MH normal col-12 ">Enter your information below to model college costs.</div>
                            <div *ngIf="version == 'A'" class="simpleText-MH normal col-12 ">Enter your information below to model college costs.</div>
                        </div>
                        <div *ngIf="version == 'A'" class="col-3">
                            <button type="button" class="btn btn-dark" class="getPDF addChild" (click)="addChildItem()">Add Child</button>
                        </div> 
                        -->
<!-- 
                        <div class="row align-items-start">
                            <div class="col-2">
                                <img class="float-left" src="/assets/img/graduation-cap.png" height=43px vspace="0" hspace="0" alt="" />
                                 
                            </div>
                            <div [ngClass]="{'col-6': version == 'A', 'col-10': version == 'S'}">            
                                <div *ngIf="version == 'S'" class="simpleText-MH normal  ">Enter your information below to model college costs.</div>
                                <div *ngIf="version == 'A'" class="simpleText-MH normal  ">Enter your information below to model college costs.</div>
                            </div>
                            <div *ngIf="version == 'A'" class="col-3">
                                <button type="button" class="btn btn-dark" class="addChild" (click)="addChildItem()">Add Child</button>
                            </div>
                        </div> -->

                        <!-- <div class="row align-items-start">
                          
                                <img class="float-left col" src="/assets/img/graduation-cap.png" style="flex-grow: inherit; padding-left: 15px; padding-right: 5px;;" height=43px vspace="0" hspace="0" alt="" />
                                 
                  
                                <div  class="simpleText-MH normal col no-padding">Enter your information below to model college costs.</div>                         
                                <div *ngIf="version == 'A'" class="col">
                                    <button type="button" class="addChild right" (click)="addChildItem()">Add Child</button>
                                </div>
                        </div> -->

                        <div class="gradCapRow" >
                          
                            <img class="float-left gradCap" src="/assets/img/graduation-cap.png" style="" height=43px vspace="0" hspace="0" alt="" />
                             
              
                            <h2  style="flex: 1;" class="simpleText-MH normal no-padding">Enter your information below to model college costs.</h2>                         
                            <div *ngIf="version == 'A'" class="">
                                <button type="button" class="addChild right" [disabled]="maxChildren  > 0"  [ngClass]="{'addChildDisabled' : maxChildren  > 0}"    (click)="addChildItem()">Add Child</button>
                                <!-- <button type="button" class="addChild right" (click)="addChildItem()">Add Child</button> -->
                                
                            </div>
                        </div>



                        <!-- <br/> -->
                    </ng-container>
                </div>
                <hr class="hrUnder">

                <ng-container *ngIf="version == 'A'">
                    <!-- <div class="row align-items-start noRowGutter"> -->
                        <accordion [closeOthers]="oneAtATime">
                            <accordion-group (click)="setChild(n)" *ngFor=" let child of children; let n=index" class="child{{n}} panel" [isOpen]="activIndex === n">
                                <div class="accordion-icon" accordion-heading>
                                    <div class="openclose-child">
                                        <ng-container *ngIf="n > 0">
                                            <div role="button" alt="Open close accordion" class="align-items-center"><img alt="Open close accordion" src="/assets/img/chevron-blank.png" /><span class="childName">  {{child.childName}}</span></div>
                                        </ng-container>
                                        <ng-container *ngIf="n == 0">
                                            <div role="button" alt="Open close accordion" class="align-items-center"><img alt="Open close accordion" src="/assets/img/chevron-blank.png" /><span class="childName">  {{child.childName}}</span></div>
                                        </ng-container>
                                        <a *ngIf="n > 0" (click)="deleteChildItem(n)" class="delete-child">
                                            <img class="trashcan" src="/assets/img/trash.png"  alt="Delete this child" />
                                        </a>
                                    </div>
                                </div>
                                <csp-child [childIndex]="n"></csp-child>
                            </accordion-group>
                        </accordion>
                    <!-- </div> -->
                    <div style="margin-right: -1px;" class="row no-gutters">
                        <div class="col-12 shaded-inputs ">


                            <div class="col-12 extra-padding">
                                <div class="row form-group-inner ">
                                    <div class="col-7 no-padding align-self-start">
                                        <a *ngIf="version === 'A'" tabindex="0"  class="returnLink" (click)="openModal('resetChildren')">Return to Basic Calculator</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="col-4 colorL">
                                </div>
                                <div class="col-4 colorM">
                                </div>
                                <div class="col-4 colorR">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="version == 'S'" style="">
                    <csp-child [childIndex]="0"></csp-child>
                </div>
            </div>
            <!-- Right Panel -->
            <!-- Right Panel -->
            <!-- Right Panel [class.align-self-end]="version == 'S'" --> 
            <div class="col-sm-12 col-md-6 whiteBack">
         
                <!-- <div class="sectionHeader hrUnderR row align-items-start">
                    <div *ngIf="version === 'A'" [ngClass]="{'chartTitle-MH': product == 'MH', 'chartTitle': ''}" class="normal col-6 col-sm-6 col-md-6 col-lg-8 ">
                        Projected College Savings for {{childName}}
                    </div>
                    <div *ngIf="version === 'S'"  [ngClass]="{'chartTitle-MH': product == 'MH', 'chartTitle': ''}" class="normal col-6 col-sm-6 col-md-6 col-lg-8 ">
                        Projected College Savings
                    </div>
                    
                    <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                        <button type="button" class="btn btn-dark" class="getPDF right" (click)="openModal('getPDFModal')">Create PDF Report</button>
                    </div>
                </div> -->

                <div class="sectionHeader hrUnderR align-items-start">
                    <h2 *ngIf="version === 'A'" [ngClass]="{'chartTitle-MH': product == 'MH', 'chartTitle': ''}"  class="normal ">
                        Projected College Savings for {{childName}}
                    </h2>
                    <h2 *ngIf="version === 'S'"  [ngClass]="{'chartTitle-MH': product == 'MH', 'chartTitle': ''}" class="normal ">
                        Projected College Savings
                    </h2>
                    
                    <div style="flex: 1;" class="">
                        <button type="button" class="btn btn-dark" class="getPDF right" (click)="openModal('getPDFModal')">Create PDF Report</button>
                    </div>
                </div>

                <hr class="hrUnder">
                    
                

                <!-- Advanced Funding Chart-->
                <ng-container *ngIf="version == 'A'">
                    <app-childchart [childIndex]="childIndex"></app-childchart>
                </ng-container>
                <!-- Simple Funding Chart-->
                <ng-container *ngIf="version == 'S'">
                    <app-childchart [childIndex]="0"></app-childchart>
                </ng-container>
                <div class="col-12 calcFootNote no-padding"> <!--  *ngIf="version == 'S'" -->
                    <hr class="hrCalcROW">
                    *Projected using your Education Cost Inflation Rate input value
                </div>
             
             
                <!-- Blue Action Panel -->
                <!-- Blue Action Panel -->
                <!-- Blue Action Panel -->
                <div  *ngIf="this.customData.product !== 'MH'" class="actionMargin" [class.CTA-padding]="version !== 'S'" style="position: relative;">
                    <div class="action-card">
                        <div class="col-12 actio-title">

                            <h3 class="normal">{{customData.actiontext1}}</h3>
                            <div class="action-text">
                                {{customData.actiontext2}}
                            </div>

                            <div class="row ctaButtonWrap">
                                <a href="{{customData.contributeURL}}" target="_blank" class="btn btn-open">Open an Account</a>
                                <a href="{{customData.contributeURL2}}" target="_blank" class="btn btn-contrib">Make a Contribution</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Bottom Panel -->
    <!-- Bottom Panel -->
    <!-- Bottom Panel -->
    <!-- <div *ngIf="customData.product === 'MH'" class="container-fluid">
        <div class="container-fluid">
            <div class="row no-gutters">
                <aside role="complementary" class="col-12 specialistBar">
                
                        <div class="row">
                            <div class="col-12">
                                <div class="col-12 specialistTitle">
                                    <span class="action-title">Call to Action </span>
                                </div>
                                <div class="col-12 specialistText">
                                    Corporis, minima voluptates debitis quas velit quod sequi quis dignissimos pariatur aliquam bunt.
                                </div>
                            </div>
                        </div>
                    
                </aside>
            </div>
        </div>
    </div> -->




    <ng-template #getPDFModal>
        <div class="modal-header searchHeader">
        </div>
        <div class="modal-body">
            <nav class="row">
                <div class="col-12">
                    PDF and Email. Provide your email address before clicking "Download" to also send yourself a copy. (Optional)
                </div>
            </nav>
            <div class="row mt-3">
                <div class="col-12 align-self-start no-padright">
                    <input type="text" [(ngModel)]="attachment_email" class="attachment_email" id="attachment_email" />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <button aria-label="Click here to cancel this PDF Download window" class="btn btn-cancel float-left" (click)="closeModal('getPDFModal'); ">Cancel</button>
                </div>
                <div class="col-6">
                    <button aria-label="Download and email the PDF report" class="btn btn-primary float-right actionBtn" (click)="getPDF();">Download</button>
                </div>
            </div>

            <div class="row">
                <div *ngIf="pdfprocessing" class="col-12 mt-3 mb-1">
                    Please wait, your PDF Report is processing now...
                </div>
            </div>



        </div> <!-- end of modal-body -->
    </ng-template>



    <ng-template #resetChildren>
        <div class="modal-header searchHeader">
            <H5>Return to Basic Calculator</H5>
        </div>
        <div class="modal-body">
            <nav class="row">
                <div class="col-12">
                    By choosing to return the Basic Calculator you will lose any information you’ve input into the Advanced Calculator. Would you like to proceed?
                </div>
            </nav>
            <div class="row">
                <div class="col-6">
                    <button aria-label="Click here to cancel the switch to the Advance Version of the Calculator" class="btn btn-cancel float-left" (click)="closeModal('resetChildren'); ">Cancel</button>
                </div>
                <div class="col-6">
                    <button aria-label="Click here to switch from the Advanced to the Basic Version of the Calculator" class="btn btn-primary float-right actionBtn" (click)="switchToSimple();">Continue</button>
                </div>
            </div>
        </div> <!-- end of modal-body -->
    </ng-template>

</ng-container>