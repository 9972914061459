import { Component, OnInit, ViewChild, TemplateRef,Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Page } from '../../core/models/page.model';
import { PageService } from '../../core/services/page.service';
import { SessionService } from '../../core/services/session.service';
import { SYSTEM } from '../../core/setup/system';
import { BsModalRef } from 'ngx-bootstrap/modal/public_api';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/user.service';
import { CalcService } from '../../core/services/calc.service';
import { CustomModeService } from '../../core/services/custommode.service';
import { customData } from '../../core/models/custom.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cspData } from '../../core/models/cspdata.model';
import { ApiService } from '../../core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../core/services/analytics.service';
import { environment } from '../../../environments/environment';
// import { $ } from 'protractor';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {

  
  termsStatus: string;
  isFirstOpen = true;
  cspData = <cspData>{};
  cspDataArray: cspData[] = [];

  customData = <customData>{};

  @ViewChild('modalAssumptions', { static: false }) modalAssumptions: TemplateRef<any>;
  @ViewChild('modalLimits', { static: false }) modalLimits: TemplateRef<any>;
  @ViewChild('getPDFModal', { static: false }) getPDFModal: TemplateRef<any>;
  @ViewChild('resetChildren', { static: false }) resetChildren: TemplateRef<any>;

  @Input()   maxChildren: number; // Used so that ngClass picks up the change dynamically

  attachment_email: string;

  system = SYSTEM;
  children: any[];

  funded: any[];
  notfunded: any[];
  shortfall: any[];
  years: any[];

  pdfprocessing: boolean;

  childIndex: number;

  oneAtATime: boolean = true;
  activIndex: number;
  private destroyed$ = new Subject();
  colors: string[];
  version: string;
  product: string;
  allowAddChildren: boolean;
  childName: string;


  constructor(private pageService: PageService,
    private modalService: ModalService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private calcService: CalcService,
    private apiService: ApiService,
    private _http: HttpClient,
    private router: Router,
    private customModeService: CustomModeService,
    private analyticsService: AnalyticsService,
    private sessionService: SessionService,

  ) { 
    this.scrollTop();
  }

  ngOnInit() {


    this.childIndex = 0;
    this.allowAddChildren = true;
    this.children = [];
    this.pdfprocessing = false;
    this.cspDataArray = [];
    this.sessionService.clearStorage();
    this.version = this.customModeService.getVersion();
    this.product = this.customModeService.getProduct();

    this.setColors();
    this.addChildItem();

    this.userService.calcSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.cspData = data;
        if (this.cspData[0]) {

          this.setNames(this.cspData);

        }
      }
    });

    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.customData = data;
        this.version = this.customData.version;
      }
    });

    this.activIndex = 0;
    this.childName = this.cspDataArray[this.activIndex]['child_name'];


    //$(".addChild").on("click", (event) => {
    $( ".addChild" ).on( "click", function() {
        // console.log(".addChild click for " + this.children.length);
        // if (this.children.length >= SYSTEM.maxChildren) {
        //   //$(".addChild").on("click", (event) => {
        //   this.maxChildren = 1;
        // } else {
        //   this.maxChildren = 0;
        // }
  
    });

    this.scrollTop();

  }

  // Set the colors array
  setColors() {

    this.colors = [];
    for (let i = 0; i < SYSTEM.maxChildren; i++) {
      this.colors.push(this.getRandomColor());
    }

  }

  // Generate random colors
  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  // Set the child name field in the accordion header to match the child name in the form
  setNames(cspData): any {
    for (let i = 0; i < this.children.length; i++) {
      if (cspData[i]) {
        this.children[i].childName = cspData[i]['child_name'];
      }
    }
  }

  ngAfterViewInit() {
    // this.sidenavContainer.scrollable.elementScrolled().subscribe((x) => {
    //   if ((<Element>x.target).scrollTop > 0) {
    //     this.isScrolled = true;
    //   } else {
    //     this.isScrolled = false;
    //   }

     // Hack: Scrolls to top of Page after page view initialized
     let top = document.getElementById('sectionHeader');
     if (top !== null) {
       top.scrollIntoView();
       top = null;
     }


  
    // })
  }


  ngAfterViewChecked() {

    this.cd.detectChanges(); // use this so that changes in HTML like ngClass instantly picks up variable changes.  
                             // The variable has to be defined as @Input
    this.setNames(this.cspDataArray);

   

    if (this.children.length >= SYSTEM.maxChildren) {
      this.maxChildren = 1;
    } else {
      this.maxChildren = 0;
    }
    

    // Set the colored left border of accordion headers
    let childNum = (this.children ? this.children.length : 0);
    let classn = ".child" + (childNum > 0 ? childNum - 1 : 0);
    // $(classn).find(".panel").find(".card-header").css("border-left", "3px solid " + this.colors[childNum]);

    $('accordion-group.panel').each(function () {
      $(this).attr("aria-expanded", "false");
    });
    $('accordion-group.panel.panel-open').each(function () {
      $(this).attr("aria-expanded", "true");
    });
    this.childName = this.cspDataArray[this.childIndex]['child_name'];

  }
  setAdvanced() {
 
    this.analyticsService.sendEvent("switchAdvanced");
    this.customModeService.setVersion("A");
    this.version = "A"
  }

  scrollTop() {
    // window.scroll({ 
    //   top: 0, 
    //   left: 0, 
    //   behavior: 'smooth' 
    // });
  }

  setChild(child: number) {

    // for (let i = 0; i < this.cspDataArray.length; i++) {
    //   let nm = this.cspDataArray[i]['child_name'];
    //   console.log("Name: " + nm + ", college_name: " + this.cspDataArray[i]['college_name'] + ", tuition: " + this.cspDataArray[i]['tuition']);
    // }



    // Set the active child
    this.childIndex = child;
    this.cspDataArray = this.userService.getCspData();

    if (this.cspDataArray) {
      for (let i = 0; i < this.cspDataArray.length; i++) {
        this.cspDataArray[i]['active'] = false;
        this.cspDataArray[i]['childNum'] = i;
        if (i == child) {
          this.cspDataArray[i]['active'] = true;
        }
      }
    }
    this.userService.updateCspData(this.cspDataArray);
    this.childName = this.cspDataArray[child]['child_name'];

  }


  // Add a new child/accordion
  addChildItem(): void {

    let childNum = (this.children ? this.children.length : 0);
    if (this.children.length >= SYSTEM.maxChildren) {
      this.maxChildren = 1;
      //$(".addChild").addClass("addChildDisabled");
      //console.log("addChildDisabled added");

      return;
    } else {
      this.maxChildren = 0;
      //$(".addChild").removeClass("addChildDisabled");

    }
    


    let newCspData = this.userService.getUserInputs(childNum, true);
    this.cspDataArray = this.userService.getCspData();

    let childNameNum = 0;
    if (this.cspDataArray) {
      let nm = ""
      for (let i = 0; i < this.cspDataArray.length; i++) {
        nm = this.cspDataArray[i]['child_name'];
        let lastchar = nm.substr(nm.length - 2, 2);
        childNameNum = Math.max(childNameNum, (isNaN(parseInt(lastchar)) ? 0 : parseInt(lastchar)));
        // console.log("Name: " + nm + ", college_name: " + this.cspDataArray[i]['college_name'] + ", tuition: " + this.cspDataArray[i]['tuition']);
      }
    }

    let childName = "Child " + (childNameNum + 1);
    this.children.push({
      childName: childName
    });
    newCspData.child_name = childName;

    if (this.cspDataArray) {
      this.cspDataArray.push(newCspData);
      this.userService.updateCspData(this.cspDataArray);
    } else {
      this.cspDataArray = [];
      this.cspDataArray.push(newCspData);
      this.userService.updateCspData(this.cspDataArray);
    }

    this.setChild(childNum);
    this.activIndex = childNum;

    if (this.children.length >= SYSTEM.maxChildren) {
      this.allowAddChildren = false;
    }

  }

  // Delete a child/accordion
  deleteChildItem(childIndex: number) {

    if (this.children.length == 1) {
      return;
    }

   
    // if (this.children.length < SYSTEM.maxChildren) {
    //   this.maxChildren = 0;
    //   $(".addChild").removeClass("addChildDisabled");
    // } 


    this.cspDataArray = this.userService.getCspData();

    this.activIndex = (childIndex > 0 ? childIndex - 1 : 0);

    // Delete the accordion element
    this.children.splice(childIndex, 1);

    // Delete the child array element
    this.cspDataArray.splice(childIndex, 1);

    // Update the CSP data after deletion
    this.userService.updateCspData(this.cspDataArray);

    this.setChild(this.activIndex);
    this.childIndex = this.activIndex;

    if (this.children.length < SYSTEM.maxChildren) {
      this.allowAddChildren = true;
    }

    // console.log("");
    // console.log("deleteChildItem");
    // for (let i = 0; i < this.cspDataArray.length; i++) {
    //   let nm = this.cspDataArray[i]['child_name'];
    //   console.log("Name: " + nm + ", college_name: " + this.cspDataArray[i]['college_name'] + ", tuition: " + this.cspDataArray[i]['tuition']);
    // }

  }

  switchToSimple() {

    window.location.reload();


    // this.queryversion
    // this.customModeService.setVersion("S");
    // this.version = "S";
    // let x = window.location.href.split("?")[0];
    // alert(x);
    // location.replace
    //this.router.navigateByUrl("/ver/S");
    // this.switchVersion = true;
    // for (let childIndex = 0; childIndex < this.children.length; childIndex++) {
    //   // Delete the accordion element
    //   this.children.splice(childIndex, 1);
    //   // Delete the child array element
    //   this.cspDataArray.splice(childIndex, 1);
    // }
    // this.cspDataArray = this.userService.getCspData();
    // this.userService.updateCspData(this.cspDataArray);
    // this.activIndex = 0;
    // this.cspDataArray = this.userService.getCspData();
    // this.customModeService.setVersion("S");
    // this.version = "S";
    // this.cspCalc();
    // this.closeModal('resetChildren');

    this.scrollTop();
    
  }


  getPDF() {


    this.pdfprocessing = true;
    var mydate = new Date();
    var filename = "CSP-" + Math.floor(Math.random() * 1000000) + "-" + mydate.getTime();

    this.cspDataArray = this.userService.getCspData();
    for (let i = 0; i < this.cspDataArray.length; i++) {
      this.cspDataArray[i]['filename'] = filename;
      this.cspDataArray[i]['funded_color'] = this.customData.fundedcolor;
      this.cspDataArray[i]['shortfall_color'] = this.customData.shortfallcolor;
      this.cspDataArray[i]['unfunded_color'] = this.customData.unfundedcolor;
      this.cspDataArray[i]['wat_color'] = this.customData.watcolor;
      this.cspDataArray[i]['pid'] = this.customData.product;
      this.cspDataArray[i]['pageTitle'] = this.customData.pageTitle;
      this.cspDataArray[i]['reportTitle'] = this.customData.reportTitle;
      this.cspDataArray[i]['attachment_email'] = this.attachment_email;
      this.cspDataArray[i]['privacypolicyURL'] = this.customData.privacypolicyURL;
      this.cspDataArray[i]['disclosureCompany'] = this.customData.disclosureCompany;
    }


    /*
 if (thisurl.indexOf('.cloud') === -1) {
            return this.httpClient.post<any>('http://pdfservice/pdfreport/csp_createreport.php', calcdata, { headers: myheaders });
        } else if (thisurl.indexOf('uat') && thisurl.indexOf('.cloud')) {
            return this.httpClient.post<any>('https://rss-csp-pdf-uat1.ssnc.cloud/pdfreport/csp_createreport.php', calcdata, { headers: myheaders });
        } else {
            return this.httpClient.post<any>('https://rss-csp-pdf-prod1.ssnc.cloud/pdfreport/csp_createreport.php', calcdata, { headers: myheaders });
        }
    */

    let wnd = null;
    if (this.customData.isMobile) {
      wnd = window.open("", "_blank");
    }

    /*
  pdfServiceUATUrl: 'https://rss-csp-pdf-uat1.ssnc.cloud/',
  cspServiceUATUrl: 'https://rss-csp-data-uat1.ssnc.cloud/',
  pdfServicePRODUrl: 'https://rss-csp-pdf-prod1.ssnc.cloud/',
  cspServicePRODUrl: 'https://rss-csp-data-prod1.ssnc.cloud/'
  */
    this.apiService.genPDFData(this.cspDataArray).subscribe(
      resp => {
        let ret = resp;
        const jsonCalcData = resp;
        let url = "";

        if (jsonCalcData) {
          this.apiService.getPDF(jsonCalcData).subscribe(
            response => {
              if (response['retstatus'] == true) {
                url = location.origin;
                  //if (url.indexOf('.cloud') === -1) {
                // console.log("URL: " + url);
                if (url.indexOf('.cloud') === -1 && url.indexOf('.com') === -1) {
                  // console.log("Not Cloud");
                  if (this.customData.isMobile) {
                    wnd.document.location.href = "http://pdfservice/pdfreport/pdf/" + filename + ".pdf";
                  } else {
                    window.open("http://pdfservice/pdfreport/pdf/" + filename + ".pdf");
                  }

                } else if (url.indexOf('wealthmsi') !== -1) { 
                  //console.log("Production");
                  if (this.customData.isMobile) {
                    wnd.document.location.href = environment.pdfServicePRODUrl + "pdfreport/pdf/" + filename + ".pdf";
                  } else {
                    window.open(environment.pdfServicePRODUrl + "pdfreport/pdf/" + filename + ".pdf");
                  }
                } else {
                  //console.log("UAT");
                  if (this.customData.isMobile) {
                    wnd.document.location.href = environment.pdfServiceUATUrl + "pdfreport/pdf/" + filename + ".pdf";
                  } else {
                    window.open(environment.pdfServiceUATUrl + "pdfreport/pdf/" + filename + ".pdf"); // , '_blank'
                  }
                }
                
              }
              this.pdfprocessing = false;
              this.closeModal("getPDFModal");
            }
          );
        }
      }
    );

  }


  openModal(modalName: string) {
    switch (modalName) {
      case 'getPDFModal':
        this.analyticsService.sendEvent("generatePDF");
        this.modalService.show(this.getPDFModal, modalName);
        break;
      case 'resetChildren':
        this.analyticsService.sendEvent("switchBasic");
        this.modalService.show(this.resetChildren, modalName);
        break;
    }
  }

  closeModal(modalName: string) {
    this.modalService.hide(modalName);
  }

}
