import { Injectable } from '@angular/core';
import { Page } from '../models/page.model';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  page = <Page>{};
  pageSubject = new BehaviorSubject<Page>({} as Page);

  constructor(private router: Router, private userService: UserService) { }

  initPage(page: Page) {
    this.page = page;
    this.pageSubject.next(page);
  }

  navNext() {

    // // let version = this.userService.getVersion();
    // // let bonusCommission = this.userService.getBonusCommission();
    // let nextPage = "pay";

    // switch (this.page.currentPage) {

    //   case "pay":
    //     nextPage = (bonusCommission === 'N' ? (version === 'T' ? 'ric' : 'ytdcontrib') : 'bonuscommission');
    //     console.log("pay -> " + nextPage + " for version: " + version + ", bonusCommission: " + bonusCommission);
    //     break;

    //   case 'bonuscommission':
    //     nextPage = (version === 'T' ? 'ric' : 'ytdcontrib');
    //     console.log("bonuscommission -> " + nextPage + " for version: " + version + ", bonusCommission: " + bonusCommission);
    //     break;

    //   case 'ric':
    //     nextPage = 'ytdcontrib';
    //     break;

    //   case 'ytdcontrib':
    //     nextPage = 'currentcontrib';
    //     break;

    //   case 'currentcontrib':
    //     nextPage = "results";
    //     break;

    //   case "results":
    //     break;

    // }

    // // this.router.navigate(['/' + this.page.nextPage]);
    // this.router.navigate(['/' + nextPage]);

  }

  navBack() {

    // let version = this.userService.getVersion();
    // let bonusCommission = this.userService.getBonusCommission();
    // let prevPage = '/';

    // switch (this.page.currentPage) {

    //   case "pay":
    //     prevPage = "";
    //     break;

    //   case 'bonuscommission':
    //     prevPage = "pay";
    //     break;

    //   case 'ric':
    //     prevPage = 'bonuscommission';
    //     prevPage = (bonusCommission === 'N' ? "pay" : 'bonuscommission');
    //     break;

    //   case 'ytdcontrib':
    //     prevPage = 'ric';
    //     prevPage = (version !== 'T' ? (bonusCommission === 'N' ? "pay" : 'bonuscommission') : 'ric');
    //     console.log("ytdcontrib -> " + prevPage + " for version: " + version + ", bonusCommission: " + bonusCommission);
    //     break;

    //   case 'currentcontrib':
    //     prevPage = 'ytdcontrib';
    //     break;

    //   case "results":
    //     prevPage = 'currentcontrib';
    //     break;

    // }
    // // this.router.navigate(['/' + this.page.prevPage]);
    // this.router.navigate(['/' + prevPage]);

  }

  navPage(pageName: string) {
    this.router.navigate(['/' + pageName]);
  }

  initBasicPage() {
    // const page: Page = {
    //   currentPage: '',
    //   nextPage: '',
    //   prevPage: '',
    //   firstPage: true,
    //   lastPage: true,
    //   progress: 0,
    //   showIntro: false,
    //   pageTitle: '',
    //   skipBtn: false
    // };
    // this.initPage(page);
  }
}
