import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';


import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { customData } from './core/models/custom.model';
import { UserService } from './core/services/user.service';
import { CustomModeService } from './core/services/custommode.service';
import { AnalyticsService } from './core/services/analytics.service';
import cssVars from 'css-vars-ponyfill';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig]
})
export class AppComponent implements OnInit, OnDestroy {

  pid: any;
  version: string;
  product: string;
  pageTitle: string;
  customData = <customData>{};
  private destroyed$ = new Subject();


  constructor(private userService: UserService,
    private router: Router,
    private customModeService: CustomModeService,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    config: NgbTooltipConfig,
    @Inject(DOCUMENT) private document: Document) {
    // nav and links
    this.subscribeNavigation();
    this.subscribeLinks();

    // tooltip config
    config.placement = 'auto';
    config.triggers = 'click';
  }

  scrollToTop() {
    this.document.documentElement.scrollTop = 0;
    }
    
  ngOnInit() {

    this.scrollToTop();

    this.activatedRoute.queryParamMap.subscribe(queryParams => {

      this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
        if (data !== null) {
          this.customData = data;
          this.version = this.customData.version;

          if (this.customData.product !== "" && this.customData.product !== null) {
            this.setCustomTheme();

            // NEW 8/19
            this.pageTitle = this.customModeService.customData.pageTitle;
            this.userService.initUser();
          }
        }
      });


      this.product = queryParams.get("pid");
      this.version = queryParams.get("ver");

      this.customModeService.setVersion((this.version == null ? "S" : this.version));
      this.customModeService.setProduct(this.product);



    })



  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  subscribeNavigation() {
    // navigation changes
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      // scroll to top of page
      window.scrollTo(0, 0);

      // send page to GA
      this.analyticsService.sendPageview(evt);
    });
  }

  subscribeLinks() {
    if (this.analyticsService.trackLinks) {
      // capture all click events on document
      document.onclick = (ev => {
        const node = ev.target as HTMLAnchorElement;
        if (node.tagName === 'A') {
          const url = node.href.replace(node.baseURI, '');
          if (node.target.indexOf('blank') > -1) {
            // external link - track it
            this.analyticsService.sendLink(url);
          }
        }
      });
    }
  }


  setCustomTheme() {

    var element = document.documentElement;

    if (this.customData.product == "MH" || this.customData.product === undefined) {

      this.customData.product = "MH";
      cssVars({
        //onlyLegacy: false,

        variables: {
          '--body-background-color': 'transparent',
          '--logo-background': 'url(/assets/img/MAR_logo.jpg) no-repeat top left',
          '--logo-width': '150px',
          '--logo-height': '128px',
          '--helpicon': 'url("/assets/img/help_icon_MH.png") no-repeat',
          '--helpiconover': 'url("/assets/img/help_icon_MH_over.png") no-repeat',
          '--plusicon': 'url("/assets/img/chevron-down.png") no-repeat',
          '--minusicon': 'url("/assets/img/chevron-up.png") no-repeat',

          '--funded-color': this.customData.fundedcolor,
          '--unfunded-color': this.customData.unfundedcolor,
          '--savings-color': this.customData.savingscolor,
          '--wat-color': this.customData.watcolor,
          '--openbtncolor': this.customData.openbtncolor,
          '--openbtncolorhover': this.customData.openbtncolorhover,
          '--linkcolor': this.customData.linkcolor,
          '--actionbackground': this.customData.actionbackground,
          '--actionbackgroundcolor': this.customData.actionbackgroundcolor,
          '--highlightcolor1': this.customData.highlightcolor1,
          '--highlightcolor2': this.customData.highlightcolor2,
          '--grayshade': this.customData.grayshade,
          '--popoverbackground': this.customData.popoverbackground,
          '--popovertextcolor': this.customData.popovertextcolor,
          '--openbtnfontcolor': this.customData.openbtnfontcolor,
          '--openbtnfontsize': this.customData.openbtnfontsize,
          '--btnborderradius': this.customData.btnborderradius,
          '--colorL': this.customData.colorL,
          '--colorM': this.customData.colorM,
          '--colorR': this.customData.colorR,
          '--child1accordcolor': this.customData.child1accordcolor,
          '--child2accordcolor': this.customData.child2accordcolor,
          '--child3accordcolor': this.customData.child3accordcolor,
          '--child4accordcolor': this.customData.child4accordcolor,
          '--child5accordcolor': this.customData.child5accordcolor,

          '--actionBtncolor': this.customData.actionBtncolor,
          '--actionBtnhover': this.customData.actionBtnhover,

        }
      });

    } else if (this.customData.product == "MAR") {

        cssVars({
          //onlyLegacy: false,
  
          variables: {
            '--body-background-color': 'transparent',
            '--logo-background': 'url(/assets/img/MAR_logo.jpg) no-repeat top left',
            '--logo-width': '150px',
            '--logo-height': '128px',
            '--helpicon': 'url("/assets/img/help_icon_MAR.png") no-repeat',
            '--helpiconover': 'url("/assets/img/help_icon_MAR_over.png") no-repeat',
            '--plusicon': 'url("/assets/img/icon_plus_MAR.png") no-repeat',
            '--minusicon': 'url("/assets/img/icon_minus_MAR.png") no-repeat',
  
  
            '--funded-color': this.customData.fundedcolor,
            '--unfunded-color': this.customData.unfundedcolor,
            '--savings-color': this.customData.savingscolor,
            '--wat-color': this.customData.watcolor,
            '--openbtncolor': this.customData.openbtncolor,
            '--openbtncolorhover': this.customData.openbtncolorhover,
            '--linkcolor': this.customData.linkcolor,
            '--actionbackground': this.customData.actionbackground,
            '--actionbackgroundcolor': this.customData.actionbackgroundcolor,
            '--highlightcolor1': this.customData.highlightcolor1,
            '--highlightcolor2': this.customData.highlightcolor2,
            '--grayshade': this.customData.grayshade,
            '--popoverbackground': this.customData.popoverbackground,
            '--popovertextcolor': this.customData.popovertextcolor,
            '--openbtnfontcolor': this.customData.openbtnfontcolor,
            '--openbtnfontsize': this.customData.openbtnfontsize,
            '--btnborderradius': this.customData.btnborderradius,
            '--colorL': this.customData.colorL,
            '--colorM': this.customData.colorM,
            '--colorR': this.customData.colorR,
            '--child1accordcolor': this.customData.child1accordcolor,
            '--child2accordcolor': this.customData.child2accordcolor,
            '--child3accordcolor': this.customData.child3accordcolor,
            '--child4accordcolor': this.customData.child4accordcolor,
            '--child5accordcolor': this.customData.child5accordcolor,
  
            '--actionBtncolor': this.customData.actionBtncolor,
            '--actionBtnhover': this.customData.actionBtnhover,
  
          }
        });

    } else if (this.customData.product == "AK") {

      cssVars({
        //onlyLegacy: false,
        variables: {
          '--body-background-color': 'transparent',
          '--logo-background': 'url(/assets/img/AK_logo.png) no-repeat top left',
          '--logo-width': '100px',
          '--logo-height': '105px',
          '--helpicon': 'url("/assets/img/help_icon_AK.png") no-repeat',
          '--helpiconover': 'url("/assets/img/help_icon_AK_over.png") no-repeat',
          '--plusicon': 'url("/assets/img/icon_plus_AK.png") no-repeat',
          '--minusicon': 'url("/assets/img/icon_minus_AK.png") no-repeat',

          '--funded-color': this.customData.fundedcolor,
          '--unfunded-color': this.customData.unfundedcolor,
          '--savings-color': this.customData.savingscolor,
          '--wat-color': this.customData.watcolor,
          '--openbtncolor': this.customData.openbtncolor,
          '--openbtncolorhover': this.customData.openbtncolorhover,
          '--linkcolor': this.customData.linkcolor,
          '--actionbackground': this.customData.actionbackground,
          '--actionbackgroundcolor': this.customData.actionbackgroundcolor,
          '--highlightcolor1': this.customData.highlightcolor1,
          '--highlightcolor2': this.customData.highlightcolor2,
          '--grayshade': this.customData.grayshade,
          '--popoverbackground': this.customData.popoverbackground,
          '--popovertextcolor': this.customData.popovertextcolor,
          '--openbtnfontcolor': this.customData.openbtnfontcolor,
          '--openbtnfontsize': this.customData.openbtnfontsize,
          '--btnborderradius': this.customData.btnborderradius,
          '--colorL': this.customData.colorL,
          '--colorM': this.customData.colorM,
          '--colorR': this.customData.colorR,
          '--child1accordcolor': this.customData.child1accordcolor,
          '--child2accordcolor': this.customData.child2accordcolor,
          '--child3accordcolor': this.customData.child3accordcolor,
          '--child4accordcolor': this.customData.child4accordcolor,
          '--child5accordcolor': this.customData.child5accordcolor,

          '--actionBtncolor': this.customData.actionBtncolor,
          '--actionBtnhover': this.customData.actionBtnhover,

        }
      });

    } else { // TRP default

      cssVars({
        //onlyLegacy: false,
        variables: {
          '--body-background-color': 'transparent',
          '--logo-background': 'url(/assets/img/TRP_logo.jpg) no-repeat top left',
          '--logo-width': '175px',
          '--logo-height': '56px',
          '--helpicon': 'url("/assets/img/help_icon_TRP.png") no-repeat',
          '--helpiconover': 'url("/assets/img/help_icon_TRP_over.png") no-repeat',
          '--plusicon': 'url("/assets/img/icon_plus_TRP.png") no-repeat',
          '--minusicon': 'url("/assets/img/icon_minus_TRP.png") no-repeat',

          '--funded-color': this.customData.fundedcolor,
          '--unfunded-color': this.customData.unfundedcolor,
          '--savings-color': this.customData.savingscolor,
          '--wat-color': this.customData.watcolor,
          '--openbtncolor': this.customData.openbtncolor,
          '--openbtncolorhover': this.customData.openbtncolorhover,
          '--linkcolor': this.customData.linkcolor,
          '--actionbackground': this.customData.actionbackground,
          '--actionbackgroundcolor': this.customData.actionbackgroundcolor,
          '--highlightcolor1': this.customData.highlightcolor1,
          '--highlightcolor2': this.customData.highlightcolor2,
          '--grayshade': this.customData.grayshade,
          '--popoverbackground': this.customData.popoverbackground,
          '--popovertextcolor': this.customData.popovertextcolor,
          '--openbtnfontcolor': this.customData.openbtnfontcolor,
          '--openbtnfontsize': this.customData.openbtnfontsize,
          '--btnborderradius': this.customData.btnborderradius,
          '--colorL': this.customData.colorL,
          '--colorM': this.customData.colorM,
          '--colorR': this.customData.colorR,
          '--child1accordcolor': this.customData.child1accordcolor,
          '--child2accordcolor': this.customData.child2accordcolor,
          '--child3accordcolor': this.customData.child3accordcolor,
          '--child4accordcolor': this.customData.child4accordcolor,
          '--child5accordcolor': this.customData.child5accordcolor,

          '--actionBtncolor': this.customData.actionBtncolor,
          '--actionBtnhover': this.customData.actionBtnhover,

        }
      });
    }


    // NEW 8/19
    this.pageTitle = this.customModeService.customData.pageTitle;

  }

}
