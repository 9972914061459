<ng-container *ngIf="this.customData.product !== ''">
  <header role="banner" class="page-header">
    <app-header></app-header>
  </header>
  <main role="main" class="page-body container">
    <router-outlet></router-outlet>
  </main>
  <footer role="contentinfo" class="page-footer d-print-none">
    <app-footer></app-footer>
  </footer>
</ng-container>