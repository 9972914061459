import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Page } from '../../core/models/page.model';
import { SYSTEM } from '../../core/setup/system';
import { PageService } from '../../core/services/page.service';
import { UserService } from '../../core/services/user.service';
import { CustomModeService } from '../../core/services/custommode.service';
import { customData } from '../../core/models/custom.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import cssVars from 'css-vars-ponyfill';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  page: Page;
  system = SYSTEM;
  product: string;
  version: string;
  toolName: string;
  pageTitle: string;
  customData = <customData>{};
  private destroyed$ = new Subject();

  constructor(
    private pageService: PageService,
    private activatedRoute: ActivatedRoute,
    private customModeService: CustomModeService,
    private userService: UserService) { }

  ngOnInit() {

    // this.activatedRoute.queryParamMap.subscribe(queryParams => {
    //   this.product = queryParams.get("pid");
    //   this.version = queryParams.get("ver");

    //   this.customModeService.setVersion((this.version == null ? "S" : this.version));
    //   this.customModeService.setProduct(this.product);

    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.customData = data;

        this.pageTitle = this.customModeService.customData.pageTitle;
        this.product = this.customModeService.customData.product;
        this.toolName = this.customModeService.customData.toolName;
      }
    });

    // })

  }

  // setCustomTheme() {

  //   var element = document.documentElement;

  //   if (this.customData.product == "MAR") {

  //     cssVars({
  //       //onlyLegacy: false,

  //       variables: {
  //         '--body-background-color': 'transparent',
  //         '--logo-background': 'url(/assets/img/MAR_logo.jpg) no-repeat top left',
  //         '--logo-width': '150px',
  //         '--logo-height': '128px',
  //         '--helpicon': 'url("/assets/img/help_icon_MD.png") no-repeat',
  //         '--helpiconover': 'url("/assets/img/help_icon_MD_over.png") no-repeat',
  //         '--plusicon': 'url("/assets/img/icon_plus_MD.png") no-repeat',
  //         '--minusicon': 'url("/assets/img/icon_minus_MD.png") no-repeat',


  //         '--funded-color': this.customData.fundedcolor,
  //         '--unfunded-color': this.customData.unfundedcolor,
  //         '--savings-color': this.customData.savingscolor,
  //         '--wat-color': this.customData.watcolor,
  //         '--openbtncolor': this.customData.openbtncolor,
  //         '--openbtncolorhover': this.customData.openbtncolorhover,
  //         '--linkcolor': this.customData.linkcolor,
  //         '--actionbackground': this.customData.actionbackground,
  //         '--highlightcolor1': this.customData.highlightcolor1,
  //         '--highlightcolor2': this.customData.highlightcolor2,
  //         '--grayshade': this.customData.grayshade,
  //         '--popoverbackground': this.customData.popoverbackground,
  //         '--popovertextcolor': this.customData.popovertextcolor,
  //         '--openbtnfontcolor': this.customData.openbtnfontcolor,
  //         '--openbtnfontsize': this.customData.openbtnfontsize,
  //         '--btnborderradius': this.customData.btnborderradius,
  //         '--colorL': this.customData.colorL,
  //         '--colorM': this.customData.colorM,
  //         '--colorR': this.customData.colorR,
  //         '--child1accordcolor': this.customData.child1accordcolor,
  //         '--child2accordcolor': this.customData.child2accordcolor,
  //         '--child3accordcolor': this.customData.child3accordcolor,
  //         '--child4accordcolor': this.customData.child4accordcolor,
  //         '--child5accordcolor': this.customData.child5accordcolor,

  //         '--actionBtncolor': this.customData.actionBtncolor,
  //         '--actionBtnhover': this.customData.actionBtnhover,

  //       }
  //     });

  //   } else if (this.customData.product == "AK") {

  //     cssVars({
  //       //onlyLegacy: false,
  //       variables: {
  //         '--body-background-color': 'transparent',
  //         '--logo-background': 'url(/assets/img/AK_logo.png) no-repeat top left',
  //         '--logo-width': '100px',
  //         '--logo-height': '105px',
  //         '--helpicon': 'url("/assets/img/help_icon_AK.png") no-repeat',
  //         '--helpiconover': 'url("/assets/img/help_icon_AK_over.png") no-repeat',
  //         '--plusicon': 'url("/assets/img/icon_plus_AK.png") no-repeat',
  //         '--minusicon': 'url("/assets/img/icon_minus_AK.png") no-repeat',

  //         '--funded-color': this.customData.fundedcolor,
  //         '--unfunded-color': this.customData.unfundedcolor,
  //         '--savings-color': this.customData.savingscolor,
  //         '--wat-color': this.customData.watcolor,
  //         '--openbtncolor': this.customData.openbtncolor,
  //         '--openbtncolorhover': this.customData.openbtncolorhover,
  //         '--linkcolor': this.customData.linkcolor,
  //         '--actionbackground': this.customData.actionbackground,
  //         '--highlightcolor1': this.customData.highlightcolor1,
  //         '--highlightcolor2': this.customData.highlightcolor2,
  //         '--grayshade': this.customData.grayshade,
  //         '--popoverbackground': this.customData.popoverbackground,
  //         '--popovertextcolor': this.customData.popovertextcolor,
  //         '--openbtnfontcolor': this.customData.openbtnfontcolor,
  //         '--openbtnfontsize': this.customData.openbtnfontsize,
  //         '--btnborderradius': this.customData.btnborderradius,
  //         '--colorL': this.customData.colorL,
  //         '--colorM': this.customData.colorM,
  //         '--colorR': this.customData.colorR,
  //         '--child1accordcolor': this.customData.child1accordcolor,
  //         '--child2accordcolor': this.customData.child2accordcolor,
  //         '--child3accordcolor': this.customData.child3accordcolor,
  //         '--child4accordcolor': this.customData.child4accordcolor,
  //         '--child5accordcolor': this.customData.child5accordcolor,

  //         '--actionBtncolor': this.customData.actionBtncolor,
  //         '--actionBtnhover': this.customData.actionBtnhover,

  //       }
  //     });

  //   } else { // TRP default

  //     cssVars({
  //       //onlyLegacy: false,
  //       variables: {
  //         '--body-background-color': 'transparent',
  //         '--logo-background': 'url(/assets/img/TRP_logo.jpg) no-repeat top left',
  //         '--logo-width': '175px',
  //         '--logo-height': '56px',
  //         '--helpicon': 'url("/assets/img/help_icon_TRP.png") no-repeat',
  //         '--helpiconover': 'url("/assets/img/help_icon_TRP_over.png") no-repeat',
  //         '--plusicon': 'url("/assets/img/icon_plus_TRP.png") no-repeat',
  //         '--minusicon': 'url("/assets/img/icon_minus_TRP.png") no-repeat',

  //         '--funded-color': this.customData.fundedcolor,
  //         '--unfunded-color': this.customData.unfundedcolor,
  //         '--savings-color': this.customData.savingscolor,
  //         '--wat-color': this.customData.watcolor,
  //         '--openbtncolor': this.customData.openbtncolor,
  //         '--openbtncolorhover': this.customData.openbtncolorhover,
  //         '--linkcolor': this.customData.linkcolor,
  //         '--actionbackground': this.customData.actionbackground,
  //         '--highlightcolor1': this.customData.highlightcolor1,
  //         '--highlightcolor2': this.customData.highlightcolor2,
  //         '--grayshade': this.customData.grayshade,
  //         '--popoverbackground': this.customData.popoverbackground,
  //         '--popovertextcolor': this.customData.popovertextcolor,
  //         '--openbtnfontcolor': this.customData.openbtnfontcolor,
  //         '--openbtnfontsize': this.customData.openbtnfontsize,
  //         '--btnborderradius': this.customData.btnborderradius,
  //         '--colorL': this.customData.colorL,
  //         '--colorM': this.customData.colorM,
  //         '--colorR': this.customData.colorR,
  //         '--child1accordcolor': this.customData.child1accordcolor,
  //         '--child2accordcolor': this.customData.child2accordcolor,
  //         '--child3accordcolor': this.customData.child3accordcolor,
  //         '--child4accordcolor': this.customData.child4accordcolor,
  //         '--child5accordcolor': this.customData.child5accordcolor,

  //         '--actionBtncolor': this.customData.actionBtncolor,
  //         '--actionBtnhover': this.customData.actionBtnhover,

  //       }
  //     });
  //   }
  // }



  // initPageData() {
  //   // this.pageService.pageSubject.subscribe(page => {
  //   //   this.page = page;
  //   // });
  // }
  // navNext() {
  //   this.pageService.navNext();
  // }

}
