export const SYSTEM = {

    // prodURL: "wealthmsi.com",
    // qaURL: "archimedestest.com",

    prodURL: "archimedes.com",
    qaURL: "archimedestest.com",
    maxTuition: 100000,
    maxNumYears: 10,
    maxAge: 17,
    maxSavings: 500000,
    maxMonthlyContrib: 10000,
    maxAnnualContrib: 120000,
    maxPctAmtFunded: 100,
    maxChildren: 5,
    maxage_of_education: 90,
    maxrate_of_return: 10,
    maxinflation: 10,

    // Assumptions
    age_of_education: 18,
    years_attending: 4,
    inflation: 5,
    rate_of_return: 6,

    monthly_contrib: 100,
    annual_contrib: 0,
    initial_contrib: 1000,
    savings: 5000,
    age: 1,
    help_age_basic: "Assumes child is under the age of 17 and will begin college at 18 and attend college for 4 years.  "
        + "You can change the assumptions for your specific situation by selecting the Use the Advanced Calculator link below.",

    help_age_advanced: "Assumes child is under the age of 17 and will begin college at 18 and attend college for 4 years.  "
        + "You can change the assumptions in the fields below for your specific situation.",

    help_pctamtfunded: "This percent may vary based on student’s other sources of college funding including financial aid, scholarships, family’s current income, etc.",
    help_useAdvanced: "Use the Advanced Calculator to change additional variables such as College Start Age, Years Attending College, Hypothetical Rate of Return, College Cost Inflation Rate, etc.",
    help_rate_of_return: "The College Savings Calculator uses the specified hypothetical fixed annual rate of return to estimate hypothetical earnings on education savings.",
    help_inflation: "Education cost inflation rate assumed at 5% but may vary by school type, region and other factors.",
    help_cost: 'Here are the average costs of one year of college <i>in today`s dollars</i> '
        + ' for several types of school. In addition to tuition, all costs include '
        + ' room &amp; board on campus, except for public two-year schools for which '
        + ' an estimate of off-campus living expenses is included.  Please click the '
        + ' "Select Cost or Specific College" link below this input section to view '
        + ' and select other averages or colleges.',

    states: [
        // { value: "NAT", name: "NAT" },
        { value: "AK", name: "AK" },
        { value: "AL", name: "AL" },
        { value: "AR", name: "AR" },
        { value: "AZ", name: "AZ" },
        { value: "CA", name: "CA" },
        { value: "CO", name: "CO" },
        { value: "CT", name: "CT" },
        { value: "DC", name: "DC" },
        { value: "DE", name: "DE" },
        { value: "FL", name: "FL" },
        { value: "GA", name: "GA" },
        { value: "HI", name: "HI" },
        { value: "IA", name: "IA" },
        { value: "ID", name: "ID" },
        { value: "IL", name: "IL" },
        { value: "IN", name: "IN" },
        { value: "KA", name: "KA" },
        { value: "KS", name: "KS" },
        { value: "KY", name: "KY" },
        { value: "LA", name: "LA" },
        { value: "MA", name: "MA" },
        { value: "MD", name: "MD" },
        { value: "ME", name: "ME" },
        { value: "MI", name: "MI" },
        { value: "MN", name: "MN" },
        { value: "MO", name: "MO" },
        { value: "MS", name: "MS" },
        { value: "MT", name: "MT" },
        { value: "NC", name: "NC" },
        { value: "ND", name: "ND" },
        { value: "NE", name: "NE" },
        { value: "NH", name: "NH" },
        { value: "NJ", name: "NJ" },
        { value: "NM", name: "NM" },
        { value: "NV", name: "NV" },
        { value: "NY", name: "NY" },
        { value: "OH", name: "OH" },
        { value: "OK", name: "OK" },
        { value: "OR", name: "OR" },
        { value: "PA", name: "PA" },
        { value: "PR", name: "PR" },
        { value: "RI", name: "RI" },
        { value: "SC", name: "SC" },
        { value: "SD", name: "SD" },
        { value: "TN", name: "TN" },
        { value: "TX", name: "TX" },
        { value: "UT", name: "UT" },
        { value: "VA", name: "VA" },
        { value: "VT", name: "VT" },
        { value: "WA", name: "WA" },
        { value: "WI", name: "WI" },
        { value: "WV", name: "WV" },
        { value: "WY", name: "WY" }
    ]


};
