<ng-container *ngIf="this.customData.product !== ''">
    <form novalidate [formGroup]="cspFormGroup" autocomplete="off">
        <div role="form" class="row no-gutters"> <!--  no-gutters -->
            <div class="col-12 shaded-inputs">

                <input type="hidden" formControlName="college_textname" />

                <input type="hidden" formControlName="room_board" />
                <input type="hidden" formControlName="searchType" />
                <input type="hidden" formControlName="state" />
                <input type="hidden" formControlName="filename" />
                <input type="hidden" formControlName="pid" />
                <input type="hidden" value="{{childIndex}}" formControlName="childNum" />
                <input type="hidden" formControlName="tuition" />
                <input type="hidden" formControlName="college_name" />

                <div *ngIf="version == 'A'" class="col-12  extra-padding">
                    <div class="row form-group-inner form-group-noborder">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label for="child_name{{childIndex}}" class="cspLabel">Child's Name</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input type="text" [ngClass]="{'mh-form-control': product == 'MH'}"   class="form-control noborder" 
                                (blur)="checkForm()" formControlName="child_name" id="child_name{{childIndex}}" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.child_name"></app-form-error>
                        </div>
                    </div>
                </div>
                <div class="col-12 extra-padding">
                    <div class="row form-group-inner" [class.form-group-noborder]="version=='S'">
                        <div class=" col-7 no-padding">
                            <div class="label-wrap">
                                <label class="cspLabel" for="age{{childIndex}}">Child's Age
                                    <button *ngIf="version == 'A'" type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p1="ngbPopover" (click)="infoIconClick(p1, 'Child Age')" triggers="manual"
                                        [ngbPopover]="infoPopupTextAgeAdvanced" aria-label="Click for more info" placement="bottom left" data-trigger="focus" data-html="true" data-content="Popover text for Child Age">
                                        <span aria-label="Click for more info" class="sr-only">More Info</span>
                                    </button>

                                    <button *ngIf="version !== 'A'" type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p1="ngbPopover" (click)="infoIconClick(p1, 'Child Age')" triggers="manual"
                                        [ngbPopover]="infoPopupTextAgeBasic" aria-label="Click for more info" placement="bottom left" data-trigger="focus" data-html="true" data-content="Popover text for Child Age">
                                        <span aria-label="Click for more info" class="sr-only">More Info</span>
                                    </button>
                                </label>
                                <ng-template #infoPopupTextAgeAdvanced>
                                    <div [innerHTML]="system.help_age_advanced"></div>
                                </ng-template>
                                <ng-template #infoPopupTextAgeBasic>
                                    <div [innerHTML]="system.help_age_basic"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: numberMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder" 
                            (change)="checkForm()" id="age{{childIndex}}" formControlName="age" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.age"></app-form-error>
                        </div>
                    </div>
                </div>
                <div class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label for="savings{{childIndex}}">Initial Contribution</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: currencyMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="checkForm()" id="savings{{childIndex}}" formControlName="savings" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.savings"></app-form-error>
                        </div>
                    </div>
                </div>
                <div class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label for="monthly_contrib{{childIndex}}">Monthly Contribution</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: currencyMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="checkForm()" id="monthly_contrib{{childIndex}}" formControlName="monthly_contrib" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.monthly_contrib"></app-form-error>
                        </div>
                    </div>
                </div>
                <div *ngIf="version == 'A'" class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label for="annual_contrib{{childIndex}}">Other Annual Contributions</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input aria-describedby=" annual_contribDetail1" appNumeric [textMask]="{mask: currencyMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="checkForm()" id="annual_contrib{{childIndex}}" formControlName="annual_contrib" />
                        </div>
                        <div class="col-5 offset-7 text-right no-padding">
                            <app-form-error [errorMessage]="formErrors?.annual_contrib"></app-form-error>
                            <span id="annual_contribDetail1" class="label-descriptor d-inline-flex">Consider funds from gifts, bonuses, and tax
                                refunds</span>
                        </div>
                    </div>
                </div>

                <!-- Don't show for SS&C Demo - MH (Metrohill)-->
                <div *ngIf="version == 'A' && product != 'MH'" class="col-12 extra-padding">
                    <div class="row form-group-inner ">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label class="" for="pctamtfunded{{childIndex}}">% To Be Funded by your 529 Account</label>
                                <button type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p2="ngbPopover" (click)="infoIconClick(p2, '% To Be Funded')" triggers="manual" [ngbPopover]="infoPopupText" aria-label="Click for more info"
                                    placement="auto" data-trigger="focus" data-html="true" data-content="Popover text for % to be funded">
                                    <span aria-label="Click for more info" class="sr-only">More Info</span>
                                </button>
                                <ng-template #infoPopupText>
                                    <div [innerHTML]="system.help_pctamtfunded"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: percentMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block" (blur)="checkForm()" id="pctamtfunded{{childIndex}}" formControlName="pctamtfunded" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.pctamtfunded"></app-form-error>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 extra-padding ">
                    <div class="row form-group-inner noMargin">
                        <div class="col-7 no-padding">
                            <div class="label-wrap label-cost">
                                <label class="cspLabel" for="tuition{{childIndex}}">Current Annual College <span class="nowrap">Cost
                                    <button type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p3="ngbPopover" (click)="infoIconClick(p3, 'College Cost')" triggers="manual" [ngbPopover]="infoPopupTextCost"
                                        aria-label="Click for more info" placement="bottom left" data-trigger="focus" data-html="true" data-content="Popover text for % to be funded">
                                        <span aria-label="Click for more info" class="sr-only">More Info</span>
                                    </button>
                                    </span>
                                </label>
                                <ng-template #infoPopupTextCost>
                                    <div [innerHTML]="system.help_cost"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <div id="College CostAddOns1">
                                <input aria-required="true" appNumeric [textMask]="{mask: currencyMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block" (blur)="checkForm()" id="tuition{{childIndex}}" formControlName="tuition" aria-label="Current Annual College Cost - with an option to select average costs of specific colleges"
                                    aria-describedby="College CostAddOns1" />
                                <app-form-error [errorMessage]="formErrors?.tuition"></app-form-error>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group-inner noPaddingTop">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label>&nbsp;</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <div id="College CostAddOns1">
                                <!-- only shown if cost shown is selected from the database of costs-->
                                <span class="label-descriptor college-detail">
                                    <span class="sr-only">Cost displayed is for:</span>
                                <span>{{college_name}}</span>
                                <br>
                                <a tabindex="0" role="button" class="collegeSearch inline-block" (click)="openModal(collegeSearchModal, 'collegeSearchModal')">Select Cost or Specific College</a>
                                </span>
                            </div>
                        </div>     
                    </div>
                </div>
               

                <div *ngIf="version == 'A'" class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label class="" for="rate_of_return{{childIndex}}">Hypothetical Average Annual Rate of <span class="nowrap">Return

                                    <button type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p4="ngbPopover" (click)="infoIconClick(p4, 'Rate of Return')" triggers="manual" [ngbPopover]="infoPopupText" aria-label="Click for more info"
                                        placement="auto" data-trigger="focus" data-html="true" data-content="Popover text for Hypothetical Average Annual Rate of Return">
                                        <span aria-label="Click for more info" class="sr-only">More Info</span>
                                    </button></span>
                                </label>
                                <ng-template #infoPopupText>
                                    <div [innerHTML]="system.help_rate_of_return"></div>
                                </ng-template>
                                <!-- </div> -->
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: percentMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="checkForm()" id="rate_of_return{{childIndex}}" formControlName="rate_of_return" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.rate_of_return"></app-form-error>
                        </div>
                    </div>
                </div>
                <div *ngIf="version == 'A'" class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap ">
                                <label for="age_of_education{{childIndex}}">College Start Age</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: numberMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="setAge($event);checkForm()" id="age_of_education{{childIndex}}" formControlName="age_of_education" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.age_of_education"></app-form-error>
                        </div>
                    </div>
                </div>
                <div *ngIf="version == 'A'" class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label for="years_attending{{childIndex}}">Years Attending College</label>
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: numberMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="checkForm()" id="years_attending{{childIndex}}" formControlName="years_attending" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.years_attending"></app-form-error>
                        </div>
                    </div>
                </div>
                <div *ngIf="version == 'A'" class="col-12 extra-padding">
                    <div class="row form-group-inner">
                        <div class="col-7 no-padding">
                            <div class="label-wrap">
                                <label class="" for="inflation{{childIndex}}">College Cost Inflation <span style="white-space: nowrap">Rate 
                                    <button type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p5="ngbPopover" (click)="infoIconClick(p5, 'Inflation Rate')" triggers="manual" [ngbPopover]="infoPopupText" aria-label="Click for more info"
                                        placement="auto" data-trigger="focus" data-html="true" data-content="Popover text for College Cost Inflation Rate">
                                        <span aria-label="Click for more info" class="sr-only">More Info</span>
                                    </button></span>
                                </label>
                                <ng-template #infoPopupText>
                                    <div [innerHTML]="system.help_inflation"></div>
                                </ng-template>
                                <!-- </div> -->
                            </div>
                        </div>
                        <div class="col-5 no-padright">
                            <input appNumeric [textMask]="{mask: percentMask}" type="tel" [ngClass]="{'mh-form-control': product == 'MH'}"  class="form-control noborder inline-block " (blur)="checkForm()" id="inflation{{childIndex}}" formControlName="inflation" />
                        </div>
                        <div class="col-12 no-padding">
                            <app-form-error [errorMessage]="formErrors?.inflation"></app-form-error>
                        </div>
                    </div>
                </div>
                <div *ngIf="version == 'S'" class="col-12 extra-padding">
                    <div class="row form-group-inner ">
                        <div class="col-12 center mt-3 mb-3">
                            <a tabindex="0" class="useAdvanced" (click)="setAdvanced()">Use the Advanced Calculator</a>
                            <button type="button" class="btn_type_icons" data-toggle="popover" data-placement="bottom" data-original-title="" title="" popoverClass="" #p6="ngbPopover" (click)="infoIconClick(p6, 'Advanced Calculator')" triggers="manual" [ngbPopover]="infoPopupText"
                                aria-label="Click for more info" placement="bottom left" data-trigger="focus" data-html="true" data-content="Popover text for Advanced Calculator">
                                <span aria-label="Click for more info" class="sr-only">More Info</span>
                            </button>
                            <ng-template #infoPopupText>
                                <div [innerHTML]="system.help_useAdvanced"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <!-- Special color bottom border for Alaska -->
                <div *ngIf="version == 'S' && product == 'AK'" class="row no-margin">
                    <div class="col-4 colorL">
                    </div>
                    <div class="col-4 colorM">
                    </div>
                    <div class="col-4 colorR">
                    </div>
                </div>
            </div>
        </div>
    </form>



    <div class="container-fluid">

        <!-- College Search Modal Window -->
        <!-- College Search Modal Window -->
        <!-- College Search Modal Window -->
        <ng-template #collegeSearchModal>

            <div class="modal-header searchHeader">
                <h1 class="modal-title pull-left">Annual College Cost</h1>
                <button aria-label="Click here to select a specific college or average cost" type="button" class="close pull-right" aria-label="Close Annual College Cost Window" (click)="closeSearch('collegeSearchModal', 0);">
                    <!-- closeModal('collegeSearchModal')-->
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <nav class="row">
                    <!-- <div class="col-6 no-padding left">
                        <a style="text-align: center;" (click)="setDisplay('average')" [class.activeNavText]="displayAverage" class="navSearch">Average Costs</a>
                    </div>
                    <div class="col-6 no-padding right">
                        <a style="text-align: center;" (click)="setDisplay('colleges')" [class.activeNavText]="!displayAverage" class="navSearch">Specific College</a>
                    </div> -->
                    <!-- <div  class="col-6 no-padding"> -->
                        <!-- <tabset>
                          <tab heading="Basic title" id="tab1">Average Costs</tab>
                          <tab heading="Basic title">Specific College</tab>
                        </tabset>
                      </div> -->


                    <!-- <div  class="col-12 no-padding">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button (click)="setDisplay('average')" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Average Costs</button>
                            </li>
                            <li class="nav-item" role="presentation">
                            <button (click)="setDisplay('colleges')"  class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Specific College</button>
                            </li>
                        </ul>

                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                              <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                              <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                              <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
                            </div>
                          </nav>
                          <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">...</div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
                          </div>


                    </div> -->
                    <div class="col-11 offset-1 no-padding searchtabset">
                    <tabset>
                        <tab (selectTab)="setDisplay('average')" heading="Average Costs" id="tab1"></tab>
                        <tab (selectTab)="setDisplay('colleges')" heading="Specific College"></tab>
                      </tabset>
                    </div>

                      
                </nav>
                <fieldset>
                    <div class="row mt-3">
                        <div class="col-12">
                            
                            <div *ngIf="displayAverage" class="form-check custom-control custom-radio">
                                <input (click)="searchCSP('search_by_avg', 'NAT')" (click)="setNatAvg(true)" checked type="radio" class="custom-control-input" [checked]="searchType === 'N'" name="option{{childIndex}}" id="setNatAvg{{childIndex}}" />
                                <label for="setNatAvg{{childIndex}}" class="label-offset custom-control-label">National Averages</label>
                            </div>
                            <div *ngIf="!displayAverage" class="form-check form-group">
                                <label for="searchNameData{{childIndex}}" class=""> Search by College Name</label>
                                <input type="text" (change)="searchCSP('search_by_name', $event.target.value)" class="search-name form-control ml-2" id="searchNameData{{childIndex}}" [(ngModel)]="searchNameData" />
                            </div>
                    
                        </div>
                        <div class="col-12 mt-2">
                        
                                <div class="form-check custom-control custom-radio">
                                    <input type="radio" class="custom-control custom-control-input " [checked]="searchType === 'A'" (click)="setNatAvg(false)" *ngIf="displayAverage" name="option{{childIndex}}" id="setStateAvg{{childIndex}}" />
                                    <label for="setStateAvg{{childIndex}}" [class.custom-control-label]="displayAverage" [class.label-offset]="displayAverage">{{(displayAverage ? 'State Averages' : 'Search by State' )}}</label>
                                </div>
                                <div class="form-check custom-control custom-radio">
                                    <select [(ngModel)]="selectedState" *ngIf="displayAverage && product !=='TRP'" aria-label="Choose State" [disabled]="searchType == 'N'" (change)="searchCSP('search_by_avg', 'N')" class="pickstate custom-select" [(ngModel)]="searchStateAvgData" tabindex="0"
                                        name="pickstate1" id="pickstate1">
                                        <option *ngFor="let st1 of states; let n = index" [selected]="st1.name=='selectedState' || n == 0" [value]="st1.value">{{st1.name}}</option>
                                    </select>
                                    <select *ngIf="displayAverage && product === 'TRP'" aria-label="Choose State" [disabled]="searchType == 'N'" (change)="searchCSP('search_by_avg', 'N')" class="pickstate custom-select" [(ngModel)]="searchStateAvgData" tabindex="0" name="pickstate1" id="pickstate1">
                                        <option *ngFor="let st1 of states; let n = index" [selected]="n == 0" [value]="st1.value">{{st1.name}}</option>
                                    </select>

                                    <select *ngIf="!displayAverage" aria-label="Choose State" (change)="searchCSP('search_by_state', searchStateAvgData)" class="pickstate custom-select" [(ngModel)]="searchStateAvgData" tabindex="0" name="pickstate1" id="pickstate1">
                                        <option *ngFor="let st1 of states; let n = index" [selected]="n == 0" [value]="st1.value">{{st1.name}}</option>
                                    </select>
                                </div>
                        
                        </div>
                    </div>
                
                    <div class="row">
                        <div class="col-12 pl-4 pr-4">
                        </div>
                    </div>
                    <div class="row mt-3">
                        
                            <div *ngIf="!displayAverage" class="col-6">
                                <div class="form-check custom-control custom-radio">
                                    <input aria-label="In-state Tuition" class="custom-control-input" type="radio" [checked]="searchInState" (click)="setInState(true);" id="instatetuition{{childIndex}}" name="inoutstatetuition{{childIndex}}" style="margin-right: 10px;" />
                                    <label class="labelWide label-offset custom-control-label" for="instatetuition{{childIndex}}">In-state Tuition</label>
                                </div>
                            </div>
                            <div *ngIf="!displayAverage" class="col-6">
                                <div class="form-check custom-control custom-radio">
                                    <input aria-label="Out-of-state Tuition" class="custom-control-input" type="radio" [checked]="!searchInState" (click)="setInState(false);" id="outstatetuition{{childIndex}}" name="inoutstatetuition{{childIndex}}" style="margin-right: 10px;" />
                                    <label class="labelWide label-offset custom-control-label" for="outstatetuition{{childIndex}}">Out-of-state Tuition</label>
                                </div>
                            </div>
                            <div class="col-6 mt-1">
                                <div class="form-check custom-control custom-radio">
                                    <input aria-label="Include Room & Board" class="custom-control-input" style="z-index: 1;opacity:1;" type="radio" [checked]="includeRoomBoard" (click)="setincludeRoomBoard(true);" id="includeRoomBoard{{childIndex}}" name="includeRoomBoard{{childIndex}}"
                                        style="margin-right: 10px;" />
                                    <label class="labelWide label-offset custom-control-label" for="includeRoomBoard{{childIndex}}">Include Room & Board</label>
                                </div>
                            </div>
                            <div class="col-6 mt-1">
                                <div class="form-check custom-control custom-radio">
                                    <input aria-label="Tuition and Fees only" class="custom-control-input" type="radio" [checked]="!includeRoomBoard" (click)="setincludeRoomBoard(false);" id="tuitiononly{{childIndex}}" name="includeRoomBoard{{childIndex}}" style="margin-right: 10px;" />
                                    <label class="labelWide label-offset custom-control-label" for="tuitiononly{{childIndex}}">Tuition and Fees only</label>
                                </div>
                            </div>
                    
                    </div>
                </fieldset>

                <div class="row mt-1 mb-1">
                    <div class="col-12 pl-4 pr-4">
                        <div *ngIf="customData.isMobile" class="mt-4 mb-1">Click the box below for a list of college cost choices.</div>
                        <!--  [(ngModel)]="selectedCollegeIndex" -->
                        <select (click)="set_college_selected($event, n)" aria-label="Select a school from the results below" id="pickschool" size="8" class="search-results custom-select">
                            <ng-container *ngIf="displayAverage">
                                <option [selected]="n==0" *ngFor="let colldata of searchResults; let n = index" style="pointer-events:all" class="even" value="{{n}}">
                                    {{ (searchResults[n].tuition + (searchResults[n].books ? searchResults[n].books : 0) + (includeRoomBoard ? searchResults[n].room_board : 0)) | currency:'USD':'symbol':'1.0-0' }} - {{ searchResults[n].name }}</option>
                            </ng-container>
                            <ng-container *ngIf="!displayAverage">
                                <option [selected]="n==0" *ngFor="let colldata of searchResults; let n = index" style="pointer-events:all" class="even" value="{{n}}">
                                    {{ (searchInState ? searchResults[n].tuit_in : searchResults[n].tuit_out)  + (includeRoomBoard ? searchResults[n].room_board : 0) | currency:'USD':'symbol':'1.0-0' }} - {{  searchResults[n].name + ", " + (searchResults[n].city
                                + ", " + searchResults[n].state ) }}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="row mt-1 mb-1">
                    <div class="col-12 pl-4 pr-4" *ngIf="isAverage">
                        <i>National Average Cost Data: &copy; {{customData.year}}. The College Board, “Trends in College Pricing <span>{{customData.year-1}}</span>."</i>
                    </div>
                    <div class="col-12 pl-4 pr-4">
                        <i>Individual College Cost Data: © {{customData.year}} SS&C.</i>
                    </div>
                </div>
                <div class="row">
                    <div style="padding-left: 25px;" class="col-6">
                        <button aria-label="Click here to cancel this college cost selection window" class="btn btn-cancel float-left" (click)="closeSearch('collegeSearchModal', 0); ">Cancel</button>
                    </div>
                    <div style="padding-right: 25px;" class="col-6">
                        <button [disabled]="selectedCollegeIndex == null" aria-label="Click here to use the college cost you have selected" class="btn btn-primary float-right actionBtn" (click)="closeSearch('collegeSearchModal', 1); ">Select</button>
                    </div>
                </div>
            </div>
            <!-- end of modal-body -->
        </ng-template>
    </div>

</ng-container>