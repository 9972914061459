import { Component, ViewChild, OnInit, Input, AfterViewInit, SimpleChanges } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CalcService } from '../../core/services/calc.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomModeService } from '../../core/services/custommode.service';
import { customData } from '../../core/models/custom.model';
import { UtilService } from '../../core/services/util.service';
import { UserService } from '../../core/services/user.service';
import { cspData } from '../../core/models/cspdata.model';

import * as Highcharts from "highcharts";

import Accessibility from 'highcharts/modules/accessibility';

import * as $ from 'jquery';

import patternFill from 'highcharts/modules/pattern-fill';
patternFill(Highcharts);

@Component({
  selector: 'app-childchart',
  templateUrl: './childchart.component.html',
  styleUrls: ['./childchart.component.scss']
})
export class ChildchartComponent implements OnInit {

  @Input() childIndex = 0;

  funded: any[];
  notfunded: any[];
  shortfall: any[];
  years: any[];

  totalShortfall: number;
  totalSavings: number;

  customData = <customData>{};
  savingscolor: string;

  cspData = <cspData>{};
  cspDataArray: cspData[] = [];
  
  SAVINGSTEXT: any;
  SAVE_PCT_TEXT: any;
  COSTTEXT: any;
  COSTTEXT0: any;
  COVERTEXT1: any;
  COVERTEXT2: any;
  COVERTEXT3: any;
  COVERTEXT4: any;

  showGraph: boolean;

  calc_total_cost: any;
  calc_savings_pct: any;
  estimated_total_cost: any;

  calc_pct_funded: any;
  calc_amt_funded: any;
  calc_surplus_funded: any;
  calc_pctamtfunded: any;
  calc_pctamtfunded_MINUSWat: any;

  calc_monthly_additions_to_savings: any;
  calc_yearly_additions_to_savings: any;
  calc_monthly_savings_required: any;
  calc_total_funded: any;
  calc_wat_total: any;

  recommended_savings_add: any;
  recommended_savings_reduce: any;
  recommended_addl_monthly_savings: any;

  childName: any;


  Highcharts: typeof Highcharts = Highcharts; // required

  // chart
  optionsAdvanced: Object;
  optionsSimple: Object;
  chartAdvanced: any;
  chartSimple: any;
  version: string;

  jsonCalcData = <any>{};
  private destroyed$ = new Subject();

  constructor(
    private currencyPipe: CurrencyPipe,
    private calcService: CalcService,
    private customModeService: CustomModeService,
    private utilService: UtilService,
    private userService: UserService
  ) { }

  ngOnInit() {

    this.version = this.customModeService.getVersion();

    this.showGraph = true;
    
    this.customModeService.customSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.customData = data;
        this.version = this.customData.version;
      }
    });

    this.userService.calcSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.cspData = data;
        if (this.cspData[0]) {


        }
      }
    });

    this.calcService.calcSubject.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data !== null) {
        this.jsonCalcData = data;
        if (this.jsonCalcData.length) {

          this.calcChartData();
          this.setCalcText(0);
          this.cspDataArray = [];
          this.cspDataArray = this.userService.getCspData();
          this.childName =  this.cspDataArray[this.childIndex].child_name

          //if (this.version == "A") {
            this.initAdvChartData();
            this.updateAdvChartData();
          // } else {
          //   this.initSimpleChartData();
          //   this.updateSimpleChartData();
          // }

        }
      }
    });



  // <div id="chart-containerAdvanced">

  // <div id="chart-table"></div>



  }


  ngOnChanges(changes: SimpleChanges) {

    this.calcChartData();
    this.setCalcText(0);

    //if (this.version == "A") {
      this.initSimpleChartData();
      this.updateSimpleChartData();
      this.initAdvChartData();
      this.updateAdvChartData();
    // } else {
    //   this.initSimpleChartData();
    //   this.updateSimpleChartData();
    // }

  }

  initSimpleChartData() {

    ////////////////////////////////////
    //
    //  Simple Chart
    //
    // Highcharts chart options
    //

    let yadj = ($(document).width() <= 400 ? -40 : -50);

    const that = this;
    this.optionsSimple = {
      chart: {
        height: 150,
        spacingBottom: 0,
        type: 'bar',
        borderColor: '#CCC',  
        borderWidth: 0,
        backgroundColor: 'transparent',
        marginLeft: 0,
        marginRight: -50,
        marginBottom: 40,
        marginTop: -30,
        animation: { duration: 0 },
        style: {
          fontFamily: "Helvetica, Arial"
        }
      },

      tooltip: {
        shadow: false,
        borderWidth: 0,
        enabled: true,
        formatter: function () {
          return that.currencyPipe.transform(this.y, 'USD', 'symbol', '1.0-0');
        },

        positioner: function (labelWidth, labelHeight, point) {
          return {
            x: point.plotX - (point.h / 2 + labelWidth / 2) - 5,  // position label over center of bar segment
            y: point.plotY - 80
          };
        }

        // positioner: function () {
        //   return { x: 90, y: -10 };
        // },
      },

      title: {
        visible: false,
        text: ''
      },
      xAxis: {
        visible: false,

      },
      yAxis: {
        visible: false,
        endOnTick: false, // stacked bar - make the chart cover the full width

        // min: 0,
        // title: {
        //   text: 'College Funding'
        // }
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        symbolHeight: 13,
        symbolWidth: 13,
        y: yadj,
        
        align: 'center',
        // layout: 'bottom',
        maxWidth: '100%',
        // align: 'left',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemStyle: {
          cursor: "pointer",
          fontSize: '14px',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },

       

        reversed: true
      },
      plotOptions: {
        series: {
          events: {
            //Disable click of legend
            legendItemClick: function () {
              return false;
            },
            mouseOver: function () {
              return false;
            },
          },
          stacking: 'normal',
          borderWidth: 0,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        bar: {
          pointPadding: 0.2,
          maxPointWidth: 40,

          color: {
            pattern: {
              // id: "custom-pattern-1",
              path: {
                d: "M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7",
                strokeWidth: 1.5
              },
              height: 6,
              width: 6,
              color: "#ccc"
            }
          }
        }

      },
      credits: {
        enabled: false
      }
    };

  }

  updateSimpleChartData() {

    const thispct = ((this.totalShortfall + this.totalSavings) / this.totalSavings) * 100;
    //
    //  Simple Chart
    //
    const that = this;
    const seriesSimple = [];
    seriesSimple.push({
      name: 'Shortfall',
      data: [this.totalShortfall],
      index: 0,
      // color: '#DA8214'
      color: this.customData.shortfallcolor,
      // color: {
      //   pattern: {
      //     color: "#ccc"
      //   }
      // }
    });


    var textColor = (this.customModeService.product === "MAR" ? this.customData.highlightcolor1 : "#FFFFFF");

    seriesSimple.push({


      // name: 'Estimated Savings',
      name: 'Funded',
      index: 1,
      data: [this.totalSavings],
      dataLabels: {
        formatter: function () {
          return '' + that.calc_pct_funded + '%';
        },
        enabled: true,
        style: {
          color: textColor,
          fontSize: "22px",
          fontWeight: "bold",
          textOutline: "none"
        }
      },
      color: this.customData.fundedcolor // '#004a69'
    });

    this.optionsSimple['series'] = seriesSimple;

  }


  updateAdvChartData() {

    // if (this.years.length == 0) {
    //   return
    // }

    let notfundedsum = 0;
    // for (let i = 0; i < this.notfunded.length; i++) {
    //   notfundedsum += this.funded[i];
    // }




    // add x-axis
    const xAxis = [];
    xAxis.push({
      labels: {
        enabled: true,
        style: { "fontSize": "14px","fontFamily": "Helvetica, Arial, sans-serif", "fontWeight": "bold",  "color": "this.customData.highlightcolor2" } //"#054c70"
      },
      title: { text: '' }, /* Years */
      categories: this.years,
      lineColor: 'transparent',
      tickLength: 0
    });
    this.optionsAdvanced['xAxis'] = xAxis;


    const series = [];
    series.push({
      states: { hover: { enabled: false } },
      name: 'Funded',
      data: this.funded,
      color: this.customData.fundedcolor, // '#05C3DE', //  this.fundedcolor
      index: 0,
      legendIndex: 0,
    });
    // add data series
    
    series.push({
      states: { hover: { enabled: false } },
      name: 'Shortfall',
      data: this.shortfall,
      //color: this.customData.shortfallcolor, // '#909093', //this.unfundedcolor,
      // //color: '#DA8214',
      color: {
        pattern: {
          color: this.customData.shortfallcolor
        }
      },
      index: 0,
      legendIndex: 2,
    });


    if (notfundedsum > 0) {
      series.push({
        states: { hover: { enabled: false } },
        name: 'Not Funded by you',
        data: this.notfunded,
        color: this.customData.unfundedcolor, // '#909093', //this.unfundedcolor,
        index: 0,
        legendIndex: 1,
      });
    }


    this.optionsAdvanced['series'] = series;

  }





  /**
     * Init chart data for Highcharts.
     *
     * @memberOf SummaryComponent
     */
  initAdvChartData() {

    // Highcharts chart options
    const that = this;
    this.optionsAdvanced = {
      chart: {
        type: 'column',
        height: 340,
        backgroundColor: 'transparent',
        marginTop: 25,
        marginBottom: 105,
        animation: { duration: 0 },
        style: {
          fontFamily: "NimbusSanNov-Reg, Helvetica"
        }
      },

      title: {
        text: '',
        useHTML: true,
        style: {
          color: '#363636',
          align: 'center',
          'background-color': '#fff',
          'text-align': 'center',
          'fontFamily': 'Helvetica, Arial, sans-serif',
          'width': '100%',
        }
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',  
        // x: 20, y: -20,
        x: -10, y: -5,
        floating: true,
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        symbolRadius: 0,
        symbolHeight: 13,
        symbolWidth: 13,
        useHTML: true,
        enabled: true,
        accessibility:{
          enabled:true
        },
        itemStyle: { "fontSize": "14px", "fontWeight": "bold", "fontFamily": "Helvetica, Arial, sans-serif", "line-height": "20px", "textOverflow": "ellipsis" },
        //labelFormatter: function () { return '<div >' + this.name + '</div>'; },
        labelFormatter: function () {
          return '<span title="'+ this.name +' amount">' + this.name + '</span>' + '';
      }
      },

      // responsive: {
      //   rules: [{
      //     condition: {
      //       minWidth: 900
      //     },
      //     chartOptions: {
      //       legend: {
      //         enabled: false
      //       }
      //     }
      //   }]
      // },


      xAxis: {
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        gridLineColor: '#cccccc',
        labels: {
          rotation: 0,
          align: 'center',
          style: { "fontSize": "14px","fontFamily": "Helvetica, Arial, sans-serif", "fontWeight": "bold",  "color": "this.customData.highlightcolor2" } //"#054c70"
        },
        title: { text: null }
      },

      yAxis: {
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        gridLineColor: '#cccccc',
        title: { text: '' },
        min: 0,
        labels: {
          style: { "fontSize": "14px", "color": "#4f4f4f", "fontFamily": "Helvetica, Arial, sans-serif", "fontWeight": "bold", "textOverflow": "ellipsis" },
          // style: { "fontSize": "14px", "color": "#4f4f4f", "fontFamily": "Helvetica, Arial, sans-serif", "fontWeight": "bold", "textOverflow": "ellipsis" },
          formatter: function () {
            if (this.value > 1000) return "$" + (this.value / 1000) + "K";  // maybe only switch if > 1000
            return "$" + this.value + "K";
          }
        },
        reversedStacks: false,
      },
      tooltip: {
        enabled: true,
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        formatter: function () {
          return '<b>' + this.series.name + '</b>: ' + that.currencyPipe.transform(this.point.y, 'USD', 'symbol', '1.0-0');
        }
      },
      plotOptions: {
        series: {
          enableMouseTracking: true,
          animation: { duration: 0 },
          states: {
            // This turns OFF the hover over legend-highlight column section feature
            //   inactive: {
            //     opacity: 1
            // },
            hover: {
              enabled: false
            }
          }
        },
        column: {
          color: {
            pattern: {
              // id: "custom-pattern-1",
              path: {
                d: "M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7",
                strokeWidth: 1.5
              },
              height: 6,
              width: 6,
              color: "#ccc"
            }
          },
          stacking: 'normal',
          borderWidth: 0,
          events: {
            legendItemClick: function () {
              return false;       // will cancel the default action of removing series from chart
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{ // FundedData
        name: 'Funded',
        states: { hover: { enabled: false } },
        color: this.customData.fundedcolor, //  "#05C3DE", // that.fundedcolor,
        data: [],

      },
      { // UnfundedData
        states: { hover: { enabled: false } },
        name: 'Not Funded by you',
        color: this.customData.unfundedcolor, // '#909093', // that.unfundedcolor,
        data: []
      },
      { // ShortfallData
        name: 'Shortfall',
        states: { hover: { enabled: false } },
        color: {
          pattern: {
            color: this.customData.shortfallcolor, // "#ccc"
          }
        },
        data: []
      }]


      // responsive: {
      //   rules: [{
      //     condition: {
      //       maxWidth: 800
      //     },
      //     chartOptions: {

      //       legend: {

      //         x: 0, y: 0,

      //       }
      //     }]
      // }

    };






  }

  saveChartInstanceSimple(chartInstance) {
    this.chartSimple = chartInstance;
  }

  saveChartInstanceAdvanced(chartInstance) {
    this.chartAdvanced = chartInstance;
  }

  calcChartData() {

    this.funded = [];
    this.notfunded = [];
    this.shortfall = [];
    this.years = [];
    this.totalShortfall = 0;
    this.totalSavings = 0;

    let thisCalcData = this.jsonCalcData[this.childIndex];
    if (!thisCalcData) {
      return;
    }

    for (let i = 0; thisCalcData[i]['FundedData'] !== undefined; i++) {

      this.funded.push(thisCalcData[i]['FundedData']);
      this.notfunded.push(thisCalcData[i]['UnfundedData']);
      this.shortfall.push(thisCalcData[i]['ShortfallData']);
      this.years.push(thisCalcData[i]['XAxis']);

      this.totalShortfall += thisCalcData[i]['ShortfallData'];
      this.totalSavings += thisCalcData[i]['FundedData'];

    }

  }




  setCalcText(watTotal: any) {

    let req_mcontribs = 0;
    let mcontribs = 0;
    let yrcontribs = 0;
    let tmpval = 0;
    let contrib_term_s = "";
    let contrib_term_p = "";
    let pct_funded = 0;
    let amt_funded = 0;
    let surplus_funded = 0;


    let thisCalcData = this.jsonCalcData[this.childIndex];
    if (!thisCalcData) {
      return;
    }


    // if (!this.jsonCalcData) {
    //   return;
    // }

    // let thisCalcData = this.jsonCalcData[this.childIndex];

    for (let i = 0; i < thisCalcData.length; i++) {

      if (thisCalcData[i]['calcData'] != undefined) {

        this.calc_total_cost = parseInt(thisCalcData[i]['calc_total_cost']);
        this.calc_savings_pct = parseInt(thisCalcData[i]['calc_savings_pct']);
        this.calc_pctamtfunded = parseInt(thisCalcData[i]['calc_pctamtfunded']);
        this.calc_pctamtfunded_MINUSWat = parseInt(thisCalcData[i]['calc_pctamtfunded_MINUSWat']);
        this.calc_pct_funded = parseInt(thisCalcData[i]['calc_pct_funded']);
        this.calc_amt_funded = parseInt(thisCalcData[i]['calc_amt_funded']);
        this.calc_surplus_funded = parseInt(thisCalcData[i]['surplus_funded']);
        this.recommended_savings_add = parseInt(thisCalcData[i]['recommended_savings_add']);
        this.recommended_savings_reduce = parseInt(thisCalcData[i]['recommended_savings_reduce']);
        this.recommended_addl_monthly_savings = parseInt(thisCalcData[i]['recommended_addl_monthly_savings']);
        this.calc_monthly_additions_to_savings = parseInt(thisCalcData[i]['calc_monthly_additions_to_savings']);
        this.calc_yearly_additions_to_savings = parseInt(thisCalcData[i]['calc_yearly_additions_to_savings']);
        this.calc_monthly_savings_required = parseInt(thisCalcData[i]['calc_monthly_savings_required']);
        this.calc_total_funded = parseInt(thisCalcData[i]['calc_total_funded']);
        this.calc_wat_total = parseInt(thisCalcData[i]['calc_wat_total']);

      }

    }

    mcontribs = this.calc_monthly_additions_to_savings;
    yrcontribs = this.calc_yearly_additions_to_savings;

    contrib_term_s = (yrcontribs > 0 && mcontribs == 0 ? "year" : "month");
    contrib_term_p = (yrcontribs > 0 && mcontribs == 0 ? "annually" : "monthly");

    var amtToBeFundedText = (watTotal > 0 ? '"Remaining amount to be funded."' : '"Estimated total education cost", above.');
    var amtToBeFundedTextLess100 = (watTotal > 0 ? '"Remaining amount to be funded".""' : '";Amount to be funded", above.');
    var toCoverText100 = (watTotal > 0 ? '"Remaining amount to be funded."' : '"Estimated total education cost", above.');
    var toCoverTextLess100 = (watTotal > 0 ? '"Remaining amount to be funded."' : '"Amount to be funded", above.');

    req_mcontribs = (yrcontribs > 0 ? this.calc_monthly_savings_required : this.calc_monthly_savings_required);
    mcontribs += yrcontribs / 12;

    /*
    SAVE_PCT_TEXT: any;
    SAVINGSTEXT: any;
    COSTTEXT: any;
    COVERTEXT: any;
    */
    // In cases where the user's goal is to save 100% of future costs 
    surplus_funded = 0;
    // this.COSTTEXT = "Estimated total college cost" + (this.version === "A" ? "" : "*");
    this.COSTTEXT = "Estimated total college <span class='nowrap'>cost *</span>";
    this.COSTTEXT0 = "Amount to be funded"; // "Amount to be funded";
    this.COVERTEXT1 = "Based on your current inputs, you're projected to save";

    this.COVERTEXT1 = "Based on current savings you'll cover";
    // this.COVERTEXT1 = "Your 529 account savings goal";


    
    this.COVERTEXT2 = "You'll need to save";
    // this.COVERTEXT2 = "To cover your total estimated college cost, you'll need to save";

    // this.COVERTEXT2 = "To reach your 529 account savings goal, you'll need to save";

    this.COVERTEXT3 = "You'll need to save nothing additional monthly and may be able to reduce your savings by ";
    this.COVERTEXT4 = "You'll need to save nothing additional to meet “Estimated total education cost”, above.";

    // You'll need to savenothing
    // You'll need to savenothing

    // console.log("calc_pct_funded: " + this.calc_pct_funded);
    // console.log("calc_amt_funded: " + this.calc_amt_funded);
    // console.log("calc_pctamtfunded_MINUSWat: " + this.calc_pctamtfunded_MINUSWat)

    if (this.calc_savings_pct == 100) {

      // SAVE LESS
      if (req_mcontribs < mcontribs) {

        tmpval = mcontribs - req_mcontribs;
        this.SAVE_PCT_TEXT = "over 100% of " + toCoverText100;
        this.SAVINGSTEXT = "nothing additional " + contrib_term_p
          + (tmpval > 0 ? " and may be able to reduce your savings by " + this.utilService.currencyFormatter(tmpval) : "")
          + " to meet " + amtToBeFundedText;

        // SAVE MORE
      } else if (req_mcontribs > mcontribs) {

        tmpval = req_mcontribs - mcontribs;
        this.SAVE_PCT_TEXT = this.utilService.currencyFormatter(this.calc_amt_funded)
          + " (" + this.calc_pct_funded + "% of " + toCoverText100 + ")";
        this.SAVINGSTEXT = this.utilService.currencyFormatter(tmpval) + " additional per month"
          + " to meet " + amtToBeFundedText;

        // OK - current contribs EQUAL required contribs
      } else {

        this.SAVE_PCT_TEXT = "100% of " + toCoverText100;
        //&ldquo;Estimated total education cost", above.";
        this.SAVINGSTEXT = "nothing additional " + contrib_term_p
          + " to meet " + amtToBeFundedText;
      }

      // In cases where the user's goal is to save LESS THAN 100% of future costs 
    } else {

      // SAVE LESS
      if (req_mcontribs < mcontribs) {

        tmpval = mcontribs - req_mcontribs;
        this.SAVE_PCT_TEXT = "over 100% of " + toCoverTextLess100;
        this.SAVINGSTEXT = "nothing additional " + contrib_term_p
          + (tmpval > 0 ? " and may be able to reduce your savings by " + this.utilService.currencyFormatter(tmpval) : "")
          + " to meet " + amtToBeFundedTextLess100;

        // SAVE MORE
      } else if (req_mcontribs > mcontribs) {

        tmpval = req_mcontribs - mcontribs;
        this.SAVE_PCT_TEXT = this.utilService.currencyFormatter(this.calc_amt_funded)
          + " (" + this.calc_pct_funded + "% of " + toCoverTextLess100 + ")";
        this.SAVINGSTEXT = this.utilService.currencyFormatter(tmpval) + " additional per month"
          + " to meet " + amtToBeFundedTextLess100;

        // OK - current contribs EQUAL required contribs
      } else {

        this.SAVE_PCT_TEXT = "100% of " + toCoverTextLess100;
        this.SAVINGSTEXT = "nothing additional " + contrib_term_p
          + " to meet " + amtToBeFundedTextLess100;
      }
    }

  }


  initTableData() {

    // let i = 0;
    // // this.calcLabel = [];
    // // this.calcValue = [];
    // let thisCalcData = this.jsonCalcData[this.childIndex];
    // for (i = 0; i < thisCalcData.length; i++) {

    //   if (thisCalcData[i]['TAB_YEAR'] != undefined) {

    //     // this.table_year = thisCalcData[i]['TAB_YEAR'].split(',');
    //     // this.table_funded = thisCalcData[i]['TAB_FUNDED'].split(',');
    //     // this.table_undfunded = thisCalcData[i]['TAB_UNFUNDED'].split(',');
    //     // this.table_savings = thisCalcData[i]['TAB_SAVINGS'].split(',');
    //     // this.table_shortfall = thisCalcData[i]['TAB_SHORTFALL'].split(',');

    //   } else if (thisCalcData[i]['calcLabel'] != undefined) {

    //     // this.calcLabel = thisCalcData[i]['calcLabel'].split('|');

    //   } else if (thisCalcData[i]['calcValue'] != undefined) {

    //     //this.calcValue = thisCalcData[i]['calcValue'].split('|');

    //   } else if (thisCalcData[i]['calcData'] != undefined) {

    //     this.calc_total_cost = parseInt(thisCalcData[i]['calc_total_cost']);
    //     this.calc_savings_pct = parseInt(thisCalcData[i]['calc_savings_pct']);
    //     this.calc_pctamtfunded = parseInt(thisCalcData[i]['calc_pctamtfunded']);
    //     this.calc_pctamtfunded_MINUSWat = parseInt(thisCalcData[i]['calc_pctamtfunded_MINUSWat']);
    //     this.calc_pct_funded = parseInt(thisCalcData[i]['calc_pct_funded']);
    //     this.calc_amt_funded = parseInt(thisCalcData[i]['calc_amt_funded']);
    //     this.calc_surplus_funded = parseInt(thisCalcData[i]['surplus_funded']);

    //     this.recommended_savings_add = parseInt(thisCalcData[i]['recommended_savings_add']);
    //     this.recommended_savings_reduce = parseInt(thisCalcData[i]['recommended_savings_reduce']);
    //     this.recommended_addl_monthly_savings = parseInt(thisCalcData[i]['recommended_addl_monthly_savings']);

    //     this.calc_monthly_additions_to_savings = parseInt(thisCalcData[i]['calc_monthly_additions_to_savings']);
    //     this.calc_yearly_additions_to_savings = parseInt(thisCalcData[i]['calc_yearly_additions_to_savings']);
    //     this.calc_monthly_savings_required = parseInt(thisCalcData[i]['calc_monthly_savings_required']);
    //     this.calc_total_funded = parseInt(thisCalcData[i]['calc_total_funded']);
    //     this.calc_wat_total = parseInt(thisCalcData[i]['calc_wat_total']);

    //     // "calc_monthly_additions_to_savings": this.MONTHLY_ADDITIONS_TO_SAVINGS[i],
    //     // "calc_yearly_additions_to_savings": this.YEARLY_ADDITIONS_TO_SAVINGS[i],
    //     // "calc_monthly_savings_required": this.CALC_MONTHLY_SAVINGS_REQUIRED[i],
    //     // "calc_total_funded": this.TOTAL_FUNDED[][i]

    //   }

    // }
    // let x = 1;

  }





}