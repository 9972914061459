import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalRefs: Object = <BsModalRef>{};
  config = {
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    class: 'modal-lg',
  };

  constructor(private modalServiceBS: BsModalService) { }

  show(template: TemplateRef<any>, indx: string, useXl = false) {
    const configUse = Object.assign({}, this.config);
    if (useXl) {
      configUse.class = 'modal-md';
    }


    this.modalRefs[indx] = this.modalServiceBS.show(template, configUse);

    this.modalRefs[indx].setClass('modal-md');

  }

  hide(indx: string) {
    this.modalRefs[indx].hide();
  }

  hideAll() {
    Object.keys(this.modalRefs).forEach(key => {
      this.modalRefs[key].hide();
    });
  }

}
