import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  defaultStorageType = 'session';
  storage: Storage;
  timeoutMinutes = environment.sessionTimeoutMinutes;
  timedOut = false;

  constructor(private idle: Idle,
    private router: Router) {
    this.storage = this.getStorageType(this.defaultStorageType);
  }

  init() {

    // get seconds from timeoutMinutes property
    const timeoutSeconds = this.timeoutMinutes * 60;
    // sets an idle timeout (seconds)
    this.idle.setIdle(timeoutSeconds);
    // sets a timeout period (seconds) after idle time expires, in which user will officially be timed out
    this.idle.setTimeout(1);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // actions when timeout detected
    this.idle.onTimeout.subscribe(() => {
      this.clearStorage();
      this.timedOut = true;
      this.router.navigate(['/session']);
    });
    // reset session timer on page load
    this.resetSession();
  }

  /**
   * Reset session timeout counter.
   */
  resetSession() {
    this.idle.watch();
    this.timedOut = false;
  }

  /**
   * Clears storage.
   */
  clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   * Get a single key/value from storage.
   *
   * @param {string} key
   * @param {string} [storageType=this.defaultStorageType]
   * @returns {string}
   */
  getSessionItem(key: string, storageType: string = this.defaultStorageType): string {
    return this.storage.getItem(key);
  }

  /**
   * Save a single key/value item to storage.
   *
   * @param {string} key
   * @param {*} value
   * @param {string} [storageType=this.defaultStorageType]
   */
  saveSessionItem(key: string, value: any, storageType: string = this.defaultStorageType) {
    this.storage.setItem(key, value);
  }

  /**
   * Remove a single item from storage.
   *
   * @param {string} key
   * @param {string} [storageType=this.defaultStorageType]
   */
  removeSessionItem(key: string, storageType: string = this.defaultStorageType) {
    this.storage.removeItem(key);
  }

  /**
   * Get either session or local storage.
   *
   * @param {string} type
   * @returns {Storage}
   */
  getStorageType(type: string): Storage {
    if (type === 'local') {
      return localStorage;
    } else {
      return sessionStorage;
    }
  }

}
