<div role="row" class="row noMargins">
  <div class="col-12">
    <!-- <div class="chartSubTitle ">
      You are on track to save <span class="chartSubTitleValue">{{calc_amt_funded | currency : 'USD' : 'symbol' : '1.0-0'  }}</span>
    </div> -->
    <div class="row">
      <div class="col-sm-9 col-12"  [ngClass]="{ 'chartSubTitleAdv' :version === 'A', 'chartSubTitle' :version !== 'A' }">
        Results for {{childName}}  
      </div>
      <div class="col-sm-3 col-12">
      <a alt="Toggle Table and Graph Display" tabindex=0 class="toggleGraph" (click)="showGraph=!showGraph"> {{showGraph ? "Show Table" : "Show Graph"}} </a>
      </div>
    </div>
    <div *ngIf="showGraph" id="chart-containerAdvanced">
      <ng-container >
        <highcharts-chart alt="funded and shortfall savings for {{childName}}" tabindex=0 [(update)]="updateFlag" [Highcharts]="Highcharts" style="display: block;" *ngIf="optionsAdvanced" [options]="optionsAdvanced"></highcharts-chart>
      </ng-container>
    </div>
    <div *ngIf="!showGraph" id="chart-table">
      <table tabindex=0 role="table" class="table">

          <tr role="row">
            <th scope="col">Year</th><th role="cell" scope="col">Funded Amount</th><th role="cell" scope="col">Shortfall Amount</th>
          </tr>
          <tr role="row">
            <td role="cell">{{years[0]}}</td><td>{{funded[0] | currency : 'USD' : 'symbol' : '1.0-0' }}</td><td role="cell">{{shortfall[0] | currency : 'USD' : 'symbol' : '1.0-0' }}</td>
          </tr> 
          <tr role="row">
            <td role="cell">{{years[1]}}</td><td role="cell">{{funded[1] | currency : 'USD' : 'symbol' : '1.0-0' }}</td><td role="cell">{{shortfall[1] | currency : 'USD' : 'symbol' : '1.0-0' }}</td>
          </tr> 
          <tr role="row">
            <td role="cell">{{years[2]}}</td><td role="cell">{{funded[2] | currency : 'USD' : 'symbol' : '1.0-0' }}</td><td role="cell">{{shortfall[2] | currency : 'USD' : 'symbol' : '1.0-0' }}</td>
          </tr> 
          <tr role="row">
            <td role="cell">{{years[3]}}</td><td role="cell">{{funded[3] | currency : 'USD' : 'symbol' : '1.0-0' }}</td><td role="cell">{{shortfall[3] | currency : 'USD' : 'symbol' : '1.0-0' }}</td>
          </tr>
      </table>
    </div>



  </div>
</div>


<div role="table" class="row calcText noMargins">
  <div class="col-12">
    <!-- <div class="row mb-2 calcRow"> -->
    <div role="row" class="row calcRow ">
      <div class="col-md-7 col-6 mt-2">
        <span  [innerHTML]="COSTTEXT" class="calctext-label"></span>
      </div>
      <div class="col-md-5 col-6">
        <span class="calctext-value">{{ calc_total_cost | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
        <span class="calctext-small"></span>
      </div>
    </div>
    <ng-container>
      <!-- <ng-container *ngIf="version === 'A'"> -->
      <div role="row" class="row calcRow">
        <div class="col-md-7 col-6 mt-2">
          <span class="calctext-label">{{COSTTEXT0}}</span>
        </div>
        <div class="col-md-5 col-6">
          <span class="calctext-value">{{ calc_pctamtfunded_MINUSWat | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
          <span class="calctext-value-highlight"> ({{calc_savings_pct}}%)</span>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div role="row" class="row calcRow">
        <div class="col-md-7 col-6 mt-2">
          <span class="calctext-label">{{COVERTEXT1}}</span>
        </div>
        <div class="col-md-5 col-6">
          <span class="calctext-value">{{ calc_amt_funded | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
          <ng-container>
            <!-- <ng-container *ngIf="version === 'A'"> -->
            <span class="calctext-small calctext-highlight paren-adjust"> (</span>
            <span class="calctext-value-highlight">{{calc_pct_funded}}%</span>
            <span class="calctext-small calctext-highlight"> of savings goal) </span>
          </ng-container>
          <!-- <ng-container>
            <span class="calctext-value-highlight"> ({{calc_pct_funded}}%) </span>
          </ng-container> -->

        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="recommended_savings_add > 0">
      <div class="row calcRow">
        <div class="col-md-7 col-6 mt-2">
          <span class="calctext-label calctext-highlight">{{COVERTEXT2}}</span>
        </div>
        <div class="col-md-5 col-6 calctext-col2">
          <span class="calctext-value calctext-highlight">{{ recommended_savings_add | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
          <span class="calctext-small calctext-highlight"> additional per month</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="recommended_savings_reduce > 0">
      <div role="row" class="row calcRow">
        <div class="col-md-7 col-6 mt-2">
          <span class="calctext-label">{{COVERTEXT3}}</span>
        </div>
        <div class="col-md-5 col-6 calctext-col2">
          <span class="calctext-value">{{ recommended_savings_reduce | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
          <span class="calctext-small"> additional per month</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="recommended_savings_reduce == 0 && recommended_savings_add == 0">
      <div role="row" class="row calcRow">
        <div class="col-md-7 col-6 mt-2">
          <span class="calctext-label">{{COVERTEXT4}}</span>
        </div>
        <div class="col-md-5 col-6 calctext-col2">
          <span class="calctext-value"></span>
          <span class="calctext-small"></span>
        </div>
      </div>
    </ng-container>

  </div>

</div>