import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const gtagua: Function;    // old analytics.js
declare const gtag: Function;  // new gtag.js
declare const trackId: string; // tracking ID (set in index.html)

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  trackType = 'gtag';  // or old 'analytics'

  trackLinks = true;
  trackInputs = false;

  inputCategory = 'inputs';
  linkCategory = 'links';

  constructor(private router: Router) { }

  /**
   * Send page view to Google Analytics.
   *
   * @param {NavigationEnd} page
   */
  sendPageview(page: NavigationEnd) {
    // if (this.trackType === 'gtag') {
    //   // gtagua('config', trackId, {
    //   //   'page_path': page.urlAfterRedirects
    //   // });

      // GA4 call
      gtag('event', 'page_view', {
        page_title: page.urlAfterRedirects
      });

    // } else {
      gtagua('set', 'page', page.urlAfterRedirects);
      gtagua('send', 'pageview');
    // }
  }

  /**
   * Send multiple inputs to Google Analytics events.
   *
   * @param {*} inputs
   */
  sendAllInputs(inputs: any) {
    if (this.trackInputs) {
      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          //if (this.trackType === 'gtag') {
            gtag('event', key, {
              'event_category': this.inputCategory,
              'event_label': inputs[key],
            });

          //} else {
            gtagua('send', 'event', this.inputCategory, key, inputs[key]);
         // }
        }
      }
    }
  }

  /**
   * Send a single key/value input to Google Analytics event.
   *
   * @param {string} key
   * @param {*} value
   */
  sendSingleInput(key: string, value: any) {
    if (this.trackInputs) {
      //if (this.trackType === 'gtag') {
        gtag('event', key, {
          'event_category': this.inputCategory,
          'event_label': value,
        });
      // } else {
        gtagua('send', 'event', this.inputCategory, key, value);
     // }
    }
  }

  /**
  * Send a URL to Google Analytics.
  *
  * @param {string} url
  */
  sendEvent(evt: string) {

    const linkAction = 'internal-link';
    //if (this.trackType === 'gtag') {
      gtag('event', linkAction, {
        'event_category': this.linkCategory,
        'event_label': evt,
      });
   //} else {
      gtagua('send', 'event', this.linkCategory, linkAction, evt);
   //}

  }

  /**
   * Send a URL to Google Analytics.
   *
   * @param {string} url
   */
  sendLink(url: string) {
    let page = this.router.url.replace('/', '');
    // if (page === '') {
    //   page = 'home';
    // }
    if (this.trackLinks) {
      const linkAction = 'link-' + page;
      // if (this.trackType === 'gtag') {
        gtag('event', linkAction, {
          'event_category': this.linkCategory,
          'event_label': url,
        });
      // } else {
        gtagua('send', 'event', this.linkCategory, linkAction, url);
     //  }
    }
  }
}
